import React from "react";
import { getAllDiaryEntriesForTrafficSignVertical } from "../apis/TrafficSignVertical";
import { getAllDiaryEntriesForTrafficSignHorizontal } from "../apis/TrafficSignHorizontal";
import { DiaryEntryAdvancedForTrafficSignHorizontal, DiaryEntryAdvancedForTrafficSignVertical } from "../interfaces";

import { DiaryIcon } from "@pasport/react-ui";
import {
	BreadcrumbSetter,
	Page,
	PageHeader,
	SkeletonLoader,
	TabRoutes,
	useApiStateEffect,
} from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../core/routing";
import { TrafficSignVerticalAllDiaryList } from "../components/TrafficSign/TrafficSignVerticalAllDiaryList";
import {
	TrafficSignHorizontalAllDiaryList,
} from "../components/TrafficSignHorizontal/TrafficSignHorizontalAllDiaryList";


export const AllDiaryEntryListScreen = () => {

	const [diaryEntriesVerticalApiState] = useApiStateEffect(() => getAllDiaryEntriesForTrafficSignVertical());
	const [diaryEntriesHorizontalApiState] = useApiStateEffect(() => getAllDiaryEntriesForTrafficSignHorizontal());


	return (
		<Page>
			<SkeletonLoader
				data={[diaryEntriesVerticalApiState, diaryEntriesHorizontalApiState]}
				render={([diaryEntriesVertical, diaryEntriesHorizontal]: [DiaryEntryAdvancedForTrafficSignVertical[], DiaryEntryAdvancedForTrafficSignHorizontal[]]) => (
					<>
						<BreadcrumbSetter
							to={getAbsoluteRoutePath("vsechnaHlaseni", {})}
							label={`Poslední hlášení v obci`}
						/>
						<PageHeader title={"Poslední deníková hlášení v obci"} />
						<TabRoutes
							links={[
								{
									to: "svisle",
									label: "Hlášení k objektům svislého DZ",
									icon: <DiaryIcon />,
									content: <TrafficSignVerticalAllDiaryList items={diaryEntriesVertical} />,
								}, {
									to: "vodorovne",
									label: "Hlášení k objektům vodorovného DZ",
									icon: <DiaryIcon />,
									content: <TrafficSignHorizontalAllDiaryList items={diaryEntriesHorizontal} />,
								},
							]}
						/>
					</>
				)} />

		</Page>
	);
};