import { TrafficSignObjectList } from "../components/TrafficSignObject";
import React from "react";
import { TrafficSignEditableDetail } from "../components/TrafficSign";
import { TrafficSignObjectCreateForm } from "../components/TrafficSignObject/TrafficSignObjectCreateForm";
import { TownDistrictDto, TrafficSignVerticalDto } from "../interfaces";
import { downloadTrafficSignObjectListTable, getTrafficSignsOnCarrier } from "../apis/TrafficSignObject";
import {
	createDiaryEntryForTrafficSignVertical, downloadTrafficSignCarrierListTable,
	getDiaryEntriesForTrafficSignVertical,
} from "../apis/TrafficSignVertical";
import { TrafficSignObjectDto } from "../interfaces/TrafficSignObjectDto";
import { useNavigate } from "react-router-dom";

import { AddIcon, DetailIcon, DownloadIcon, ListIcon } from "@pasport/react-ui";
import {
	DiaryEntryCreateForm, DiaryEntryDto,
	DiaryList,
	Page,
	PageHeader,
	SkeletonLoader,
	TabContent,
	TabRoutes,
	useAlert,
	useApiStateEffect,
} from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { DownloadTableTile } from "../common/DownloadTableTile";

interface TrafficSignVerticalScreenProps {
	detail: TrafficSignVerticalDto;
	districtDetail: TownDistrictDto;
}

export const TrafficSignVerticalScreen = ({ detail, districtDetail }: TrafficSignVerticalScreenProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	const { uzemiId, nosicSvisleId } = useUrlParams();

	const [signListApiState, invalidateSignList] = useApiStateEffect(() => getTrafficSignsOnCarrier(nosicSvisleId, uzemiId));
	const [diaryListApiState, invalidateDiaryEntries] = useApiStateEffect(() => getDiaryEntriesForTrafficSignVertical(nosicSvisleId, uzemiId));

	return (
		<Page>

			<SkeletonLoader
				data={[signListApiState, diaryListApiState]}
				render={([signList, diaryList]: [TrafficSignObjectDto[], DiaryEntryDto[]]) => (
					<>
						<PageHeader title={`Nosič svislého dopravního značení ${detail.oznaceni}`} />
						<TabRoutes
							links={[{
								to: "objekty",
								label: "Seznam objektů",
								icon: <ListIcon />,
								content: (
									<TabContent
										title={"Objekty svislého dopravního značení"}
										isEmpty={signList.length === 0}
										emptyLabel={"Zatím zde není žádný objekt svislého dopravního značení."}
									>
										<TrafficSignObjectList
											items={signList}
											getLink={sign => getAbsoluteRoutePath("objektSvislehoZnaceni", {
												uzemiId,
												nosicSvisleId: nosicSvisleId,
												objektSdzId: sign.id,
											})

											}
										/>
									</TabContent>
								),
							}, {
								to: "detail",
								label: "Detail nosiče",
								icon: <DetailIcon />,
								content: (
									<TabContent title={"O nosiči"}>
										<TrafficSignEditableDetail detail={detail} districtId={uzemiId} />
									</TabContent>
								),
							}, {
								to: "denik",
								label: "Deník svislého značení",
								icon: <DetailIcon />,
								content: (
									<DiaryList
										items={diaryList}
										getLink={(diary) => getAbsoluteRoutePath("svisleZnaceniHlaseni", {
											uzemiId,
											nosicSvisleId,
											sdzHlaseniId: diary.id,
										})}
									/>
								),
							}]}
							actionLinks={[{
								to: "pridat-objekt",
								label: "Přidat objekt",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidání objektu svislého dopravního značení"}>
										<TrafficSignObjectCreateForm
											onCreate={invalidateSignList}
											districtId={uzemiId}
											carrierId={nosicSvisleId}
										/>
									</TabContent>
								),
							}, {
								to: "pridat-denik",
								label: "Přidat deníkový záznam",
								icon: <AddIcon />,
								content: (
									<TabContent title={"Přidat deníkový záznam"}>
										<DiaryEntryCreateForm
											dispatchCreate={(diaryEntry) => createDiaryEntryForTrafficSignVertical(diaryEntry, nosicSvisleId)}
											onCreateSuccess={(diaryEntryId: number) => {
												addSuccessAlert({ message: "K nosiči bylo přidáno deníkové hlášení." });
												navigate(
													getAbsoluteRoutePath("svisleZnaceniHlaseni", {
														uzemiId,
														nosicSvisleId,
														sdzHlaseniId: diaryEntryId,
													}),
												);
												invalidateDiaryEntries();
											}}
										/>
									</TabContent>
								),
							}, {
								to: "exportovat",
								label: "Exportovat",
								icon: <DownloadIcon />,
								content: (
									<DownloadTableTile
										onDownload={() => downloadTrafficSignObjectListTable(detail, districtDetail)}
										cardTitle={"Seznam objektů svislého dopravního značení"}
										buttonLabel={"Stáhnout tabulku objektů svislého dopravního značení"}
									/>

								),
							},
							]}
						/>
					</>
				)}
			/>
		</Page>
	);
};