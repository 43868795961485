import {
	TRAFFIC_SIGN_OBJECT_SORT_METHOD,
	TRAFFIC_SIGN_OBJECT_SORT_OPTIONS,
	TRAFFIC_SIGN_OBJECT_TYPE_SELECT_OPTIONS,
	TrafficSignObjectTypeValue,
} from "./useTrafficSignObjectFilter";

import { SearchInput, Select, Stack } from "@pasport/react-ui";

import { LayoutElement, SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS, SortingContainer } from "@pasport/react-common";

interface TrafficSignObjectListFilterProps {
	setSortDirection: (direction: SORT_DIRECTION) => void;
	setSortMethod: (sortMethod: TRAFFIC_SIGN_OBJECT_SORT_METHOD) => void;
	setObjectType: (objectType: TrafficSignObjectTypeValue) => void;
	setSearchQuery: (query: string) => void;
}

export const TrafficSignObjectListFilter = ({
												setSearchQuery,
												setSortMethod,
												setSortDirection,
												setObjectType,
											}: TrafficSignObjectListFilterProps) => {
	return (
		<Stack direction={"row"} spaceBetween flexWrap>
			<Stack space={2} direction="row">
				<LayoutElement width={300}>
					<SearchInput placeholder={"Hledejte dle označení či kódu"} onTextChange={setSearchQuery} />
				</LayoutElement>

				<LayoutElement>
					<Select options={TRAFFIC_SIGN_OBJECT_TYPE_SELECT_OPTIONS}
							onChange={setObjectType as (objectType: string) => void} />
				</LayoutElement>
			</Stack>
			<Stack space={2} direction="row" center>
				<SortingContainer>
					<Select
						onChange={setSortMethod as (sortMethod: string) => void}
						options={TRAFFIC_SIGN_OBJECT_SORT_OPTIONS}
					/>
					<Select
						onChange={setSortDirection as (sortMethod: string) => void}
						options={SORT_DIRECTION_SELECT_OPTIONS}
					/>
				</SortingContainer>
			</Stack>
		</Stack>
	);
};