import { useCodeImage } from "../hooks/useCodeImage";

const SignImage = ({ code, size }: { code: string; size: number }) => {
	const { getSVGPath } = useCodeImage();
	return (
		<div>
			<img src={getSVGPath(code)} height={size} style={{ borderRadius: 5 }} />
		</div>
	);
};

export const SignsCollage = ({ codes }: { codes: string[] }) => {
	const size = 20;

	const marginOffset = -size * 0.9;

	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			{codes.map((code, i) => {
				return (
					<div
						key={i + "a" + code}
						style={{
							marginRight: i != codes.length - 1 ? marginOffset : 0,
							zIndex: codes.length - i,
						}}
					>
						<SignImage code={code} size={size} />
					</div>
				);
			})}
		</div>
	);
};
