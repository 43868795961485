import {
	BreadcrumbSetter,
	LayerSwitchControl,
	LegendLayerLine,
	MapView,
	PanoramaCircleMarker,
} from "@pasport/react-common";
import { CardDivider } from "@pasport/react-ui";
import { getAbsoluteRoutePath } from "../../core/routing";
import { useMapVerticals } from "../../hooks";
import { useMapHorizontals } from "../../hooks/map/useMapHorizontals";
import { SignsCollage } from "./components/SignsCollege";

export const MapScreen = () => {
	const { apiState: apiStateHorizontals, add: addHorizontals } = useMapHorizontals();
	const { apiState: apiStateVerticals, add: addVerticals } = useMapVerticals();
	return (
		<div className={"h-100"}>
			<BreadcrumbSetter
				to={getAbsoluteRoutePath("mapa", {})}
				label={`Mapa dopravního značení v obci`}
			/>
			<div className="h-100">
				<MapView
					apiStates={[apiStateHorizontals, apiStateVerticals]}
					dataProcess={[addHorizontals, addVerticals]}
					usePanorama
					defaultPanoramaPhoto="default_pano_photo.jpg"
				>
					{(map, layers) => (
						<>
							<LegendLayerLine
								title={"Nosiče sv. značení"}
								layer={layers.verticalLayer}
								icon={
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<div>
											<SignsCollage codes={["P1", "P2", "A4"]} />
										</div>
									</div>
								}
							/>

							<LegendLayerLine
								title={"Vodorovné značení"}
								layer={layers.horizontalLayer}
								icon={
									<div style={{}}>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												transform: "scaleX(-1)",
											}}
										>
											<SignsCollage codes={["V7a", "V12c"]} />
										</div>
									</div>
								}
							/>
							{layers.panoramaMarkers.length > 0 && (
								<LegendLayerLine
									title="Panorama snímky"
									layer={layers.panoramasLayer}
									icon={
										<div
											style={{
												transform: "translate(50%, 50%)",
												height: "100%",
											}}
										>
											<PanoramaCircleMarker size={16} />
										</div>
									}
								/>
							)}

							<div className="m-2" />
							<CardDivider />
							<div className="m-2" />

							<LayerSwitchControl map={map} />
						</>
					)}
				</MapView>
			</div>
		</div>
	);
};
