import { Icon, ImageWithFallback } from "@pasport/react-ui";
import { TrafficSignObjectDto, TrafficSignObjectType } from "../../interfaces/TrafficSignObjectDto";

interface TrafficSignObjectIconProps {
	value: TrafficSignObjectDto;
}


interface FallBackSignIconProps {
	isDanger?: boolean;
}

const DefaultSignIcon = ({ isDanger }: FallBackSignIconProps) => <Icon type={"bi-question-square-fill"}
																	   variant={isDanger ? "danger" : "dark"} />;

interface SvgSignIconProps {
	src: string,
	size?: number
}

const SvgSignIcon = ({ src, size = 60 }: SvgSignIconProps) => {
	return (
		<ImageWithFallback
			fallback={(
				<DefaultSignIcon isDanger />
			)}
			src={src}
			width={size}
			style={{ maxWidth: size, maxHeight: size, width: "auto", height: "auto" }}
		/>
	);
};

export const TrafficSignObjectIcon = ({ value }: TrafficSignObjectIconProps) => {
	switch (value.typ) {
		case TrafficSignObjectType.DOPRAVNI_ZNACKA:
			return <SvgSignIcon src={`${process.env.PUBLIC_URL}/znacky/${value.kodZnaceni}.svg`} />;
		case TrafficSignObjectType.SVETELNA_SIGNALIZACE:
			return <SvgSignIcon src={`${process.env.PUBLIC_URL}/znacky/samafor.svg`} size={35} />;

		case TrafficSignObjectType.DETEKTOR_RYCHLOSTI:
			return <SvgSignIcon src={`${process.env.PUBLIC_URL}/znacky/radar.svg`} size={35} />;

		case TrafficSignObjectType.DOPRAVNI_ZRCADLO:
			return <SvgSignIcon src={`${process.env.PUBLIC_URL}/znacky/zrcadlo.svg`} size={40} />;

		default:
			return <DefaultSignIcon isDanger />;
	}
};
