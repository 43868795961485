import { useEffect } from "react";
import { useReduxApiState } from "./useReduxApiState";
import { ApiState, isApiStateDispatched } from "@pasport/react-common";

export const useReduxApiStateEffect = <T, >(key: string, dispatch: () => Promise<T>): [ApiState<T>, () => void] => {
	const { fetchApiState, apiState, invalidateApiState } = useReduxApiState<T>(key);

	useEffect(() => {
		if (!isApiStateDispatched(apiState)) {
			fetchApiState(dispatch);
		}
	}, [apiState]);

	return [apiState, invalidateApiState];
};