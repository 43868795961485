import { TrafficSignObjectDto } from "../../interfaces/TrafficSignObjectDto";
import { TrafficSignObjectIcon } from "./TrafficSignObjectIcon";
import { ListItem, Row, RowItem } from "@pasport/react-ui";

interface TrafficSignObjectItemProps {
	value: TrafficSignObjectDto,
	link: string
}

export const TrafficSignObjectItem = ({ value, link }: TrafficSignObjectItemProps) => {

	return (
		<ListItem link={link} icon={

			<span className="fs-2">
                <TrafficSignObjectIcon value={value} />
            </span>}>
			<Row>
				<RowItem label={"Označení"} size={1} priority={10}>{value.oznaceni}</RowItem>
				<RowItem label={"Typ"} size={2}>{value.typ}</RowItem>
				<RowItem label={"Kód značení"} size={3}>{value.kodZnaceni}</RowItem>
				<RowItem label={"Stav"} size={3}>{value.upresneniStavu ? <>{value.stav} <span
					className="fw-normal">({value.upresneniStavu})</span></> : value.stav}</RowItem>
				<RowItem label={"Poznámka"} size={3} priority={9}>{value.poznamka}</RowItem>
			</Row>
		</ListItem>
	);
};