import { TrafficSignVerticalDto } from "../../interfaces";
import { MapTrafficSignCarrier } from "../TrafficSignHorizontal/TrafficSignHorizontalApiDto";
import { MapTrafficSignCarrierDto, TrafficSignObjectSearchResultApiDto, TrafficSignObjectSearchResultDto, TrafficSignVerticalApiDto } from "./TrafficSignVerticalApiDto";
import { apiToDate, apiToNumber, apiToPosition, apiToString, convertInnerLocationToGPS, dateToApi, stringToApi } from "@pasport/react-common";

export const mapApiToTrafficSignVertical = (response: TrafficSignVerticalApiDto): TrafficSignVerticalDto => {
	return {
		id: apiToNumber(response.nosic_id),
		oznaceni: apiToString(response.oznaceni),

		ulice: apiToString(response.ulice),
		komunikace: apiToString(response.komunikace),
		cisloPopisne: apiToString(response.cp_co),
		datumPosledniUdrzby: apiToDate(response.datum_posl_servisu),
		datumPosledniZavady: apiToDate(response.datum_posl_zavady),
		datumInstalace: apiToDate(response.datum_instalace),
		datumPorizeni: apiToDate(response.datum_porizeni),
		datumAktualizace: apiToDate(response.datum_aktualizace),
		stav: apiToString(response.stav),
		upresneniStavu: apiToString(response.stav_upresneni),
		poznamka: apiToString(response.poznamka),
		typ: apiToString(response.typ_nosice),
		pocetObjektu: apiToNumber(response.pocet_sdz),
		pozice: apiToPosition(response.st_x, response.st_y),
		prioritniKod: apiToString(response.zobr_znaceni), //TODO is this correct?,
	};
};

export const mapTrafficSignVerticalToApi = (request: TrafficSignVerticalDto) => {
	return {
		oznaceni: stringToApi(request.oznaceni),
		ulice: stringToApi(request.ulice),
		cp_co: stringToApi(request.cisloPopisne),
		komunikace: stringToApi(request.komunikace),
		typ_nosice: stringToApi(request.typ),
		stav: stringToApi(request.stav),
		stav_upresneni: stringToApi(request.upresneniStavu),
		DXdatum_instalace: dateToApi(request.datumInstalace),
		DXdatum_porizeni: dateToApi(request.datumPorizeni),
		DXdatum_aktualizace: dateToApi(request.datumAktualizace),
		poznamka: stringToApi(request.poznamka),
	};
};

export const mapApiToTrafficSignSearchResult = (response: TrafficSignObjectSearchResultApiDto): TrafficSignObjectSearchResultDto => {
	return {
		objekt: {
			id: apiToNumber(response.sdz_id),
			oznaceni: apiToString(response.oznaceni),
			kod: apiToString(response.kod_znaceni),
		},
		nosic: {
			id: apiToNumber(response.nosic_id),
			oznaceni: apiToString(response.oznaceni_nosice),
			ulice: apiToString(response.ulice),
			komunikace: apiToString(response.komunikace),
			cisloPopisne: apiToString(response.cp_co),
		},
	};
};

export const mapApiToMapTrafficSignCarrier = (api: MapTrafficSignCarrierDto): MapTrafficSignCarrier => {
	const x = parseFloat(api.st_x || "0");
	const y = parseFloat(api.st_y || "0");

	return {
		location: convertInnerLocationToGPS([x, y]),
		oznaceni: api.oznaceni,
		uzemiId: api.uzemi_id,
		id: api.nosic_id,
		kodHlavniZnacky: api.zobr_znaceni,
		hlavniZnackaSlovne: api.znaceni_slovne,
		typNosice: api.typ_nosice,
	};
};
