import { TrafficSignCards } from "./TrafficSignCards";
import { TrafficSignVerticalDto } from "../../interfaces";
import {
	deleteTrafficSignVertical,
	deleteTrafficSignVerticalPhoto,
	getTrafficSignVerticalPhotoList,
	updateTrafficSignVerticalDetail,
	uploadTrafficSignVerticalPhoto,
} from "../../apis/TrafficSignVertical";
import { useNavigate } from "react-router-dom";

import { FormVersion, FormWithUpdate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";

interface TrafficSignEditableDetailProps {
	detail: TrafficSignVerticalDto,
	districtId: number,
}

export const TrafficSignEditableDetail = ({ detail, districtId }: TrafficSignEditableDetailProps) => {
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();
	return (
		<FormWithUpdate
			initValues={detail}
			dispatchUpdate={(updated: TrafficSignVerticalDto) => updateTrafficSignVerticalDetail(updated, districtId)}
			onUpdateSuccess={() => addSuccessAlert({ message: "Nosič svislého dopravního značení byl upraven." })}
			dispatchDelete={() => deleteTrafficSignVertical(detail.id, districtId)}
			onDeleteSuccess={() => {
				addSuccessAlert({ message: "Nosič svislého dopravního značení byl odstraněn." });
				navigate(getAbsoluteRoutePath("uzemi", { uzemiId: districtId /*TODO navigate to correct tab*/ }));
			}}
		>
			{({ mode }) => (
				<TrafficSignCards
					mode={mode}
					version={FormVersion.UPDATE}
					photoApi={{
						getList: () => getTrafficSignVerticalPhotoList(detail.id, districtId),
						upload: (file) => uploadTrafficSignVerticalPhoto(file, detail.id),
						delete: (file) => deleteTrafficSignVerticalPhoto(file, detail.id, districtId),
					}}
				/>
			)}
		</FormWithUpdate>
	);
};