import { TrafficSignHorizontalDto } from "../../interfaces";
import { ComponentProps } from "react";
import { getFormattedAddress } from "../../tech";

import { Icon, ImageWithFallback, ListItem, Row, RowItem } from "@pasport/react-ui";

import { formatDate } from "@pasport/react-common";

interface TrafficSignHorizontalItemProps {
	value: TrafficSignHorizontalDto,
	link: string
}

const TrafficSignHorizontalIcon = (props: Omit<ComponentProps<typeof Icon>, "type" | "value"> & { value: TrafficSignHorizontalDto }) => {
	return <ImageWithFallback
		fallback={(
			<Icon {...props} type="bi-exclamation-triangle-fill" />
		)}
		src={`${process.env.PUBLIC_URL}/znacky/${props.value.kodZnaceni}.svg`}
		width={60}
		style={{ maxWidth: 60, maxHeight: 60, width: "auto", height: "auto" }}
	/>;
	return <Icon {...props} type="bi-exclamation-triangle-fill" />;
};

export const TrafficSignHorizontalItem = ({ value, link }: TrafficSignHorizontalItemProps) => {
	return (
		<ListItem link={link} icon={<span className={"fs-2"}><TrafficSignHorizontalIcon value={value}
																						variant={"primary"} /></span>/*FIXME size and icon fix*/}>
			<Row>
				<RowItem label={"Označení"} size={1} priority={10}>{value.oznaceni}</RowItem>
				<RowItem label={"Kód"} size={1} priority={10}>{value.kodZnaceni}{/*KOD znaceni slovne*/}</RowItem>
				<RowItem label={"Adresa"} size={2}>{getFormattedAddress(value)}</RowItem>
				<RowItem label={"Komunikace"} size={1}>{value.komunikace}</RowItem>
				<RowItem label={"Stav"} size={1.5}>{value.upresneniStavu ? <>{value.stav} <span
					className="fw-normal">({value.upresneniStavu})</span></> : value.stav}</RowItem>
				<RowItem label={"Poslední závada"} size={1.5}
						 priority={9}>{formatDate(value.datumPosledniZavady)}</RowItem>
				<RowItem label={"Poslední údržba"} size={1.5}
						 priority={9}>{formatDate(value.datumPosledniUdrzby)}</RowItem>
				<RowItem label={"Poznámka"} size={2} priority={9}>{value.poznamka}</RowItem>
				{/*FIXME its not 12 size*/}
			</Row>
		</ListItem>
	);
};