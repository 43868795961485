import { TrafficSignDto } from "../../interfaces";
import { TrafficSignCards } from "./TrafficSignCards";
import { createTrafficSignVertical } from "../../apis/TrafficSignVertical";
import { useNavigate } from "react-router-dom";

import { FormMode, FormVersion, FormWithCreate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";

const trafficSigDefault: TrafficSignDto = {
	id: -1,
	oznaceni: "",
	cisloPopisne: "",
	datumAktualizace: null,
	datumInstalace: null,
	datumPorizeni: null,
	datumPosledniUdrzby: null,
	datumPosledniZavady: null,

	komunikace: "",
	pocetObjektu: 0, //FIXME not show any number
	poznamka: "",
	typ: "",
	ulice: "",
	stav: "",
	upresneniStavu: "",
	prioritniKod: "",

};

interface TrafficSignCreateFormProps {
	districtId: number,
	onCreate: () => void
}

export const TrafficSignCreateForm = ({ districtId, onCreate }: TrafficSignCreateFormProps) => {
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();
	/*TODO api calls and version CREATE*/
	return (
		<FormWithCreate
			initValues={trafficSigDefault}
			dispatchCreate={(sign) => createTrafficSignVertical(sign, districtId)}
			onCreateSuccess={(carrierId: number) => {
				addSuccessAlert({ message: "Byl přidán nosič svislého dopravního značení." });
				navigate(
					getAbsoluteRoutePath("nosicSvislehoZnaceni", {
						uzemiId: districtId, nosicSvisleId: carrierId,
					}),
				);
				if (onCreate) onCreate();
			}}
		>
			{() => <TrafficSignCards mode={FormMode.EDIT} version={FormVersion.CREATE} />}
		</FormWithCreate>
	);
};