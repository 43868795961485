import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux";
import { Provider } from "react-redux";
import { AppRouter } from "./routers";
import { setAppConfig } from "./core/config";
import { MapProvider } from "@pasport/react-common";

import {
	AuthProvider,
	AlertProvider,
	BreadcrumbsProvider,
	ActivityInfoProvider,
	Navbar,
	RefreshTokenGuard,
} from "@pasport/react-common";

setAppConfig();

function App() {
	return (
		<Provider store={store}>
			<AuthProvider>
				<BreadcrumbsProvider>
					<AlertProvider>
						<ActivityInfoProvider>
							<MapProvider>
								<div className="h-100 d-flex flex-column">
									<Router>
										<Navbar title="Dopravní značení" />
										<div className="flex-grow-1">
											<RefreshTokenGuard>
												<AppRouter />
											</RefreshTokenGuard>
										</div>
									</Router>
								</div>
							</MapProvider>
						</ActivityInfoProvider>
					</AlertProvider>
				</BreadcrumbsProvider>
			</AuthProvider>
		</Provider>
	);
}

export default App;
