import { DiaryEntryDto, mapDiaryEntryToApi } from "@pasport/react-common";

export const mapDiaryEntryToDzApi = (diaryEntry: DiaryEntryDto, createMode: boolean) => {
	const { IXopraveno, ...request } = mapDiaryEntryToApi(diaryEntry, createMode);

	if (createMode) {
		return {
			...request,
		};
	} else {

		return {
			opraveno: IXopraveno,
			...request,
		};
	}
};