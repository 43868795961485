import {
	mapApiToTrafficSignCode,
	mapApiToTrafficSignObject, mapTrafficSignObjectToApi,
} from "./TrafficSignObjectApiMap";
import { TrafficSignObjectDto } from "../../interfaces/TrafficSignObjectDto";
import {
	dateToString, downloadFile,
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchEntityDetail,
	fetchEntityList,
	fetchUpdateEntity, normalizeText,
} from "@pasport/react-common";
import { TownDistrictDto, TrafficSignDto } from "../../interfaces";


export const createTrafficSignObject = (sign: TrafficSignObjectDto, carrierId: number, districtId: number) => {
	//FIXME check nosic id, and map
	return fetchCreateEntity("insertObjektSDZ", {
		...mapTrafficSignObjectToApi(sign, false),
		nosic_id: carrierId,
	});
};

export const getTrafficSignObjectDetail = (signId: number, carrierId: number, districtId: number) => {
	return fetchEntityDetail(`DetailObjektSDZ/${districtId}/${carrierId}/${signId}`, mapApiToTrafficSignObject);
};

export const updateTrafficSignObjectDetail = (sign: TrafficSignObjectDto, carrierId: number, districtId: number) => {
	return fetchUpdateEntity("updateObjektSDZ", {
		...mapTrafficSignObjectToApi(sign, true),
		sdz: sign.id,
		nosic: carrierId,
		uzemi: districtId,
	});
};


export const deleteTrafficSignObject = (signId: number, carrierId: number, districtId: number) => {
	return fetchDeleteEntity("deleteObjektSDZ", {
		sdz_id: signId,
		nosic_id: carrierId,
		uzemi_id: districtId,
	});
};

//lists
export const getTrafficSignsOnCarrier = (signCarrierId: number, districtId: number) => {
	return fetchEntityList(`SeznamObjektuSDZ/${districtId}/${signCarrierId}`, mapApiToTrafficSignObject);
};

export const geTrafficSignVerticalCodes = () => {
	return fetchEntityList(`SeznamKoduSDZ`, mapApiToTrafficSignCode);
};

//exports
export const downloadTrafficSignObjectListTable = async (carrier: TrafficSignDto, district: TownDistrictDto) => {
	const fileName = `${normalizeText((district.nazev))}_objekty_svisleho_dz_${normalizeText(carrier.oznaceni)}_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportSDZ/${carrier.id}`, fileName, true);
};