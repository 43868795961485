import { TrafficSignHorizontalDto } from "../../../interfaces";
import { useState } from "react";
import { getFormattedAddress, objectStateToSortKey } from "../../../tech";
import { getFulltextSearch, SORT_DIRECTION, sortBySortMethod, sortDates, sortStrings } from "@pasport/react-common";
import { SelectOption } from "@pasport/react-ui";


export enum TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD {
	OZNACENI = "OZNACENI",
	KOD = "KOD",
	ADRESA = "ADRESA",
	KOMUNIKACE = "KOMUNIKACE",
	STAV = "STAV",
	DATUM_POSLEDNI_ZAVADY = "DATUM_POSLEDNI_ZAVADY",
	DATUM_POSLEDNI_UDRZBY = "DATUM_POSLEDNI_UDRZBY",
}

//FIXME this filtering is for traffic sign
export const TRAFFIC_SIGN_HORIZONTAL_SORT_OPTIONS: SelectOption[] = [
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.OZNACENI, label: "Označení" },
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.KOD, label: "Kód značení" },
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.ADRESA, label: "Ulice" },
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.KOMUNIKACE, label: "Komunikace" },
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.STAV, label: "Stav objektu" },
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.DATUM_POSLEDNI_ZAVADY, label: "Datum poslední závady" },
	{ value: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.DATUM_POSLEDNI_UDRZBY, label: "Datum poslední údržby" },
];

function getSortingFunc(key: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD, sortDirection: SORT_DIRECTION): (a: TrafficSignHorizontalDto, b: TrafficSignHorizontalDto) => number {
	const increasingOrder = sortDirection === SORT_DIRECTION.UP;
	//TODO check if it works with real data
	switch (key) {
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.OZNACENI:
		default:
			return (a, b) => sortStrings(a.oznaceni, b.oznaceni, increasingOrder);
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.KOD:
			return (a, b) => sortStrings(a.kodZnaceni, b.kodZnaceni, increasingOrder);
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.ADRESA:
			return (a, b) => sortStrings(getFormattedAddress(a), getFormattedAddress(b), increasingOrder);
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.KOMUNIKACE:
			return (a, b) => sortStrings(a.komunikace, b.komunikace, increasingOrder);
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.STAV:
			return (a, b) => sortStrings(objectStateToSortKey(a.stav), objectStateToSortKey(b.stav), increasingOrder);
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.DATUM_POSLEDNI_ZAVADY:
			return (a, b) => sortDates(a.datumPosledniZavady, b.datumPosledniZavady, increasingOrder);
		case TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.DATUM_POSLEDNI_UDRZBY:
			return (a, b) => sortDates(a.datumPosledniUdrzby, b.datumPosledniUdrzby, increasingOrder);
	}
}


export const useTrafficSignHorizontalFilter = (objects: TrafficSignHorizontalDto[]) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [sortMethod, setSortMethod] = useState<TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD>(TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD.OZNACENI);
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);

	const itemsFulltext = getFulltextSearch(searchQuery, objects, item => [item.oznaceni, getFormattedAddress(item), item.komunikace, item.kodZnaceni]); //FIXME filter by more column
	const filteredItems = sortBySortMethod(itemsFulltext, getSortingFunc(sortMethod, sortDirection));
	return {
		setSearchQuery,
		setSortMethod,
		setSortDirection,
		filteredItems,
	};
};