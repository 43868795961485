import { ClusterMarkerLayer, MapObject, Marker, useApiStateEffect } from "@pasport/react-common";
import { getMapHorizontalsSigns } from "../../apis/TrafficSignHorizontal";
import CarrierClusterMarker from "../../screens/map/components/Clusters/CarrierClusterMarker";
import HorizontalMarker from "../../screens/map/components/Markers/HorizontalMarker";
import HorizontalClusterMarker from "../../screens/map/components/Clusters/HorizontalClusterMarker";

type MapHorizontalsState = {
	horizontals: any[];
};

export const useMapHorizontals = () => {
	const [state, fetch] = useApiStateEffect<MapHorizontalsState>(async () => {
		return {
			horizontals: await getMapHorizontalsSigns(),
		};
	}, []);

	const add = (map: MapObject, data: MapHorizontalsState) => {
		const layer = new ClusterMarkerLayer((count) => <HorizontalClusterMarker count={count} />, map.map);

		const markers: Marker[] = [];
		data.horizontals.forEach((sign) => {
			const m = map.createGeometryMarker(sign.points, sign.grafika, (marker, map) => <HorizontalMarker sign={sign} marker={marker} map={map} />);

			markers.push(m as Marker);
		});

		layer.add(markers);
		layer.initialize();

		return {
			horizontalLayer: layer,
		};
	};

	return { apiState: state, add };
};
