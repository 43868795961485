import { TrafficSignDto } from "../../interfaces";
import { useField } from "formik";

import { DetailCard, DetailColumn, DetailItemRow, DetailRow } from "@pasport/react-ui";

import { createForm, FileDto, FileUploadDto, FormMode, FormVersion, MapObject, PhotoListCard, PositionCard, PositionDto, PositionMapObject } from "@pasport/react-common";

const Form = createForm<TrafficSignDto>();

interface TrafficSignCardsProps {
	mode: FormMode;
	version: FormVersion;
	photoApi?: {
		upload?: (upload: FileUploadDto) => Promise<any>;
		delete?: (file: FileDto) => Promise<any>;
		getList?: () => Promise<FileDto[]>;
	};
}

export const TrafficSignCards = ({ mode, version, photoApi }: TrafficSignCardsProps) => {
	const [positionField] = useField<PositionDto>("pozice");

	return (
		<DetailRow>
			<DetailColumn>
				<DetailCard title="Základní informace">
					<div>
						<Form.TextItem name={"oznaceni"} label={"Označení nosiče"} mode={mode} required />

						<DetailItemRow>
							<Form.TextItem name={"ulice"} label={"Ulice"} mode={mode} />
							<Form.TextItem name={"cisloPopisne"} label={"Číslo popisné / orientační"} mode={mode} />
						</DetailItemRow>

						<DetailItemRow>
							<Form.TextItem name={"komunikace"} label={"Komunikace"} mode={mode} />
							<span className={"w-100"} /> {/*FIXME*/}
						</DetailItemRow>
						<DetailItemRow>
							<Form.EnumItem name={"typ"} label={"Typ nosiče"} mode={mode} options={[null, "vlastní konstrukce", "na sloupu VO", "na objektu", "jiný"]} />
						</DetailItemRow>
						<DetailItemRow>
							<Form.EnumItem name={"stav"} label={"Stav nosiče"} mode={mode} options={[null, "dobrý", "uspokojivý", "špatný"]} />
							<Form.TextItem name={"upresneniStavu"} label={"Upřesnění stavu"} mode={mode} />
						</DetailItemRow>
						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>
			</DetailColumn>
			<DetailColumn>
				<DetailCard title="Data">
					<div>
						<DetailItemRow>
							<Form.NumberItem name={"pocetObjektu"} label={"Počet objektů na nosiči"} mode={mode} readonly />
						</DetailItemRow>
						<DetailItemRow>
							<Form.DateItem name={"datumInstalace"} label={"Datum instalace nosiče"} mode={mode} />
							<Form.DateItem name={"datumPorizeni"} label={"Datum pořízení v terénu"} mode={mode} />
							<Form.DateItem name={"datumAktualizace"} label={"Datum aktualizace v terénu"} mode={mode} />
						</DetailItemRow>
						<DetailItemRow>
							<Form.DateItem name={"datumPosledniZavady"} label={"Datum poslední hlášené závady"} mode={mode} readonly />
							<Form.DateItem name={"datumPosledniUdrzby"} label={"Datum poslední údržby"} mode={mode} readonly />
						</DetailItemRow>
					</div>
				</DetailCard>
				{photoApi && (
					<PhotoListCard
						hide={version === FormVersion.CREATE}
						isEditMode={mode === FormMode.EDIT}
						dispatchPhotoUpload={photoApi.upload}
						loadImages={photoApi.getList}
						onPhotoDelete={photoApi.delete}
					/>
				)}

				<PositionCard position={positionField.value} object={PositionMapObject.LAMP} mode={mode} version={version} />
			</DetailColumn>
		</DetailRow>
	);
};
