import { Badge } from "@pasport/react-common";
import React from "react";
import ZnaceniImage from "../ZnaceniImage";
// "V7a","V12c"
export default function HorizontalClusterMarker({ count }: { count: number }) {
	const size = 30;
	return (
		<div
			style={{
				filter: "drop-shadow(3px 3px 3px rgba(0,0,0,0.8))",
				transform: "translate(-50%, -50%)",
			}}
		>
			<ZnaceniImage
				kod="V1a"
				size={size}
				style={
					{
						//   transform: "rotate(-10deg)"
					}
				}
			/>
			{/* <ZnaceniImage kod='V7a' size={size} style={{
              position:"absolute",
              top: "0",
              transform: "rotate(0deg)"
          }}/> */}

			<Badge
				count={count}
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			/>
		</div>
	);
}
