import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TrafficSignObjectSearchResultDto } from "../../apis/TrafficSignVertical/TrafficSignVerticalApiDto";
import { getTrafficSignObjectBySearchQuery } from "../../apis/TrafficSignVertical";
import { getFormattedAddress } from "../../tech";

import { Card, Modal, SearchInput } from "@pasport/react-ui";
import { SkeletonLoader, TabEmptyContent, useApiState } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../../core/routing";

interface TrafficSignObjectSearchModalProps {
	visible: boolean,
	onClose: () => void,
}

export const TrafficSignObjectSearchModal = ({ visible, onClose }: TrafficSignObjectSearchModalProps) => {

	const { uzemiId } = useUrlParams();
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState("");

	const { fetchApiState, apiState } = useApiState<TrafficSignObjectSearchResultDto[]>();

	useEffect(() => {
		setSearchQuery("");
	}, [visible]);


	useEffect(() => {
		if (searchQuery !== "") {
			fetchApiState(() => getTrafficSignObjectBySearchQuery(searchQuery, uzemiId));
		} else {
			fetchApiState(() => new Promise((resolve) => resolve([])));
		}
	}, [searchQuery]);

	return (
		<Modal show={visible} onClose={onClose} colorVariant="light" align={"top"}
			   header={(
				   <>
					   <div className="fs-5 fw-bold pb-2">Vyhledat objekt svislého dopravního značení</div>
					   <SearchInput
						   placeholder={"Zadejte označení či kód ..."}
						   onTextChange={(text) => setSearchQuery(text.trim())}
						   autoFocus
						   size="large"
					   />
				   </>
			   )}
		>

			<div className="overflsow-auto">
				<SkeletonLoader
					data={[apiState]}
					render={([objectList]) => {
						return (
							<div className={"d-flex flex-column gap-3"}>
								{objectList.length > 0 ? (
									<div>
										<div className="fs-6 p-1 pt-0">Nalezené objekty ({objectList.length})</div>
										<div className={"d-flex flex-column gap-2"}>
											{objectList.map(item => {
												const address = getFormattedAddress(item.nosic);

												return (
													<Card flat
														  onPress={() => navigate(getAbsoluteRoutePath("objektSvislehoZnaceni", {
															  uzemiId,
															  nosicSvisleId: item.nosic.id,
															  objektSdzId: item.objekt.id,
														  }))}>
														<div
															className={"p-3 fs-6 d-flex align-items-center justify-content-between "}>
															<div>
																<i className="bi bi-arrow-return-right pe-4"></i>
																{item.nosic.komunikace && (
																	<>
																		<strong>{item.nosic.komunikace}</strong>
																		<i className="bi bi-chevron-right px-2 "></i>
																	</>
																)}
																{address && (
																	<>

																		<strong>{address}</strong>
																		<i className="bi bi-chevron-right px-2 "></i>
																	</>
																)}
																<span>
                                                                    Nosič <strong>{item.nosic.oznaceni}</strong>
                                                                </span>
																<i className="bi bi-chevron-right px-2"></i>
																<span>
                                                                    Objekt <strong>{item.objekt.oznaceni}</strong>
                                                                </span>
															</div>
														</div>
													</Card>
												);
											})}
										</div>


									</div>) : (
									<TabEmptyContent
										text={searchQuery === "" ? "Zadejte přesné označení či kód objektu" : (
											<div>
												Výrazu "<strong>{searchQuery}</strong>" neodpovídá žádný objekt.
											</div>
										)}
									/>
								)}

							</div>
						);
					}}
					renderLoading={() => <TabEmptyContent text={"..."} />}
				/>

			</div>

		</Modal>
	);
};