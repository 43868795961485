import { TrafficSignItem } from "./TrafficSignItem";
import { TrafficSignVerticalDto } from "../../interfaces";
import { useTrafficSignFilter } from "./useTrafficSignFilter";
import { TrafficSignListFilter } from "./TrafficSignListFilter";
import { TrafficSignVerticalApiDto } from "../../apis/TrafficSignVertical/TrafficSignVerticalApiDto";
import { Stack } from "@pasport/react-ui";
import { ListContainer, Spacing } from "@pasport/react-common";


interface TrafficSignListProps {
	items: TrafficSignVerticalApiDto[],
	getLink: (sign: TrafficSignVerticalDto) => string
}

export const TrafficSignList = ({ items, getLink }: TrafficSignListProps) => {
	const {
		filteredItems,
		setSearchQuery,
		setSortMethod,
		setSortDirection,
	} = useTrafficSignFilter(items as unknown as TrafficSignVerticalDto[]);
	//TODO add limit 100 to the list
	return <>
		<TrafficSignListFilter
			setSortDirection={setSortDirection}
			setSortMethod={setSortMethod}
			setSearchQuery={setSearchQuery}
		/>

		<Spacing space={2} />

		<ListContainer
			nextPage={100}
			items={filteredItems}
			render={(renderItems) => (
				<Stack space={2}>
					{renderItems.map(sign => <TrafficSignItem value={sign} key={sign.id} link={getLink(sign)} />)}
				</Stack>
			)}
			emptyLabel={"Filtrům neodpovídá žádný nosič svislého dopravního značení."}
		/>

	</>;
};