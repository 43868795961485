import {
	TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD,
	TRAFFIC_SIGN_HORIZONTAL_SORT_OPTIONS,
} from "./useTrafficSignHorizontalFilter";

import { SearchInput, Select, Stack } from "@pasport/react-ui";

import { LayoutElement, SortingContainer, SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS } from "@pasport/react-common";

interface TrafficSignHorizontalListFilterProps {
	setSortDirection: (direction: SORT_DIRECTION) => void;
	setSortMethod: (sortMethod: TRAFFIC_SIGN_HORIZONTAL_SORT_METHOD) => void;
	setSearchQuery: (query: string) => void;
}

export const TrafficSignHorizontalListFilter = ({
													setSearchQuery,
													setSortMethod,
													setSortDirection,
												}: TrafficSignHorizontalListFilterProps) => {
	return (
		<Stack direction={"row"} spaceBetween flexWrap>
			<Stack space={2} direction="row">
				<LayoutElement width={320}>
					<SearchInput placeholder={"Hledejte dle označení, kódu či umístění"}
								 onTextChange={setSearchQuery} />
				</LayoutElement>
			</Stack>
			<SortingContainer>
				<Select
					onChange={setSortMethod as (sortMethod: string) => void}
					options={TRAFFIC_SIGN_HORIZONTAL_SORT_OPTIONS}
				/>
				<Select
					onChange={setSortDirection as (sortMethod: string) => void}
					options={SORT_DIRECTION_SELECT_OPTIONS}
				/>
			</SortingContainer>
		</Stack>
	);
};