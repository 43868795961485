import { TrafficSignHorizontalDto } from "../../interfaces";
import { useHorizontalSignCodes } from "../../hooks/useHorizontalSignCodes";
import { useField } from "formik";
import { TrafficSignObjectType } from "../../interfaces/TrafficSignObjectDto";
import { useEffect } from "react";

import {
	createForm,
	FileDto,
	FileUploadDto,
	FormMode,
	FormVersion,
	OptionItem,
	PhotoListCard,
	PositionCard,
	PositionMapObject,
} from "@pasport/react-common";
import { DetailCard, DetailColumn, DetailItemRow, DetailRow } from "@pasport/react-ui";

const Form = createForm<TrafficSignHorizontalDto>();

interface TrafficSignHorizontalCardsProps {
	mode: FormMode;
	version: FormVersion;
	photoApi?: {
		upload?: (upload: FileUploadDto) => Promise<any>;
		delete?: (file: FileDto) => Promise<any>;
		getList?: () => Promise<FileDto[]>;
	};
}

export const TrafficSignHorizontalCards = ({
	mode,
	version,
	photoApi,
}: TrafficSignHorizontalCardsProps) => {
	const [codeField] = useField<TrafficSignObjectType>("kodZnaceni");
	const [shapeField, , shapeHelpers] = useField<"point" | "linie" | "polygon">("tvar");
	const isCrossWalk = ["V7a", "V8b", "V8c"].includes(codeField.value); //TODO V7b and V8a not?
	const { horizontalSignCodes, getHorizontalSignCodeType } = useHorizontalSignCodes();

	useEffect(() => {
		shapeHelpers.setValue(getHorizontalSignCodeType(codeField.value));
	}, [codeField.value]);
	const shape = shapeField.value;

	return (
		<DetailRow>
			<DetailColumn>
				<DetailCard title="Základní informace">
					<div>
						<Form.TextItem name={"oznaceni"} label={"Označení"} mode={mode} required />

						<DetailItemRow>
							<Form.TextItem name={"ulice"} label={"Ulice"} mode={mode} />
							<Form.TextItem
								name={"cisloPopisne"}
								label={"Číslo popisné / orientační"}
								mode={mode}
							/>
							<Form.TextItem name={"komunikace"} label={"Komunikace"} mode={mode} />
						</DetailItemRow>

						<DetailItemRow>
							<Form.EnumItem
								name={"kodZnaceni"}
								label={"Kód značení"}
								mode={mode}
								required
								options={[
									null,
									...horizontalSignCodes.map(
										(item) =>
											({
												value: item.kod,
												label: `( ${item.kod} ) ${item.nazev}`,
											}) as OptionItem
									),
								]}
							/>
							{/*<Form.TextItem name={"kodSlovne"} label={"Kód značení slovně"} mode={mode}/>*/}
						</DetailItemRow>

						{isCrossWalk && (
							<DetailItemRow>
								<Form.BooleanItem
									name={"vodiciPruh"}
									label={"Vodící pruh"}
									mode={mode}
								/>
								<Form.BooleanItem
									name={"cervenaBarva"}
									label={"Červená barva"}
									mode={mode}
								/>
							</DetailItemRow>
						)}

						<DetailItemRow>
							<Form.EnumItem
								name={"stav"}
								label={"Stav značení"}
								mode={mode}
								options={[null, "dobrý", "uspokojivý", "špatný"]}
							/>
							<Form.TextItem
								name={"upresneniStavu"}
								label={"Upřesnění stavu"}
								mode={mode}
							/>
						</DetailItemRow>

						<DetailItemRow>
							<Form.NumberItem
								name={"rozmer"}
								label={"Rozměr"}
								mode={mode}
								unit={shape === "polygon" ? "m²" : "m"}
								floatNumber
							/>{" "}
							{/*TODO jednotky */}
						</DetailItemRow>

						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>
				{photoApi && (
					<PhotoListCard
						hide={version === FormVersion.CREATE}
						isEditMode={mode === FormMode.EDIT}
						dispatchPhotoUpload={photoApi.upload}
						loadImages={photoApi.getList}
						onPhotoDelete={photoApi.delete}
					/>
				)}

				<DetailCard title="Povolení">
					<Form.DateItem name={"datumPovoleni"} label={"Datum povolení"} mode={mode} />
					<Form.TextareaItem
						name={"povoleniPoznamka"}
						label={"Informace o povolení"}
						mode={mode}
					/>
				</DetailCard>
			</DetailColumn>
			<DetailColumn>
				<DetailCard title="Data">
					<div>
						<DetailItemRow>
							<Form.DateItem
								name={"datumInstalace"}
								label={"Datum instalace značení"}
								mode={mode}
							/>
							<Form.DateItem
								name={"datumPorizeni"}
								label={"Datum pořízení v terénu"}
								mode={mode}
							/>
							<Form.DateItem
								name={"datumAktualizace"}
								label={"Datum aktualizace v terénu"}
								mode={mode}
							/>
						</DetailItemRow>
						<DetailItemRow>
							<Form.DateItem
								name={"datumPosledniZavady"}
								label={"Datum poslední hlášené závady"}
								mode={mode}
								readonly
							/>
							<Form.DateItem
								name={"datumPosledniUdrzby"}
								label={"Datum poslední údržby"}
								mode={mode}
								readonly
							/>
						</DetailItemRow>
					</div>
				</DetailCard>

				<PositionCard
					objectType={shape === "linie" ? "multilinie" : "multipolygon"}
					object={PositionMapObject.LAMP}
					mode={mode}
					version={version}
					name={"geometry"}
				/>
			</DetailColumn>
		</DetailRow>
	);
};
