import { TrafficSignDto } from "../../interfaces";
import { ComponentProps } from "react";
import { getFormattedAddress } from "../../tech";

import { Icon, ListItem, Row, RowItem } from "@pasport/react-ui";
import { formatDate } from "@pasport/react-common";


interface TrafficSignItemProps {
	value: TrafficSignDto,
	link: string
}

const TrafficSignIcon = (props: Omit<ComponentProps<typeof Icon>, "type">) => {
	return <Icon {...props} type="bi-signpost-split-fill" />;
};

export const TrafficSignItem = ({ value, link }: TrafficSignItemProps) => {
	return (
		<ListItem link={link} icon={<span className={"fs-2"}><TrafficSignIcon /></span>/*FIXME size and icon fix*/}>
			<Row>
				<RowItem label={"Označení"} size={1} priority={10}>{value.oznaceni}</RowItem>
				<RowItem label={"Adresa"} size={2}>{getFormattedAddress(value)}</RowItem>
				<RowItem label={"Komunikace"} size={1}>{value.komunikace}</RowItem>
				<RowItem label={"Počet objektů"} size={1.5}>{value.pocetObjektu}</RowItem>
				<RowItem label={"Stav"} size={1.5} priority={9}>{value.upresneniStavu ? <>{value.stav} <span
					className="fw-normal">({value.upresneniStavu})</span></> : value.stav}</RowItem>
				<RowItem label={"Poslední závada"} size={1.5}
						 priority={9}>{formatDate(value.datumPosledniZavady)}</RowItem>
				<RowItem label={"Poslední údržba"} size={1.5}
						 priority={9}>{formatDate(value.datumPosledniUdrzby)}</RowItem>
				<RowItem label={"Poznámka"} size={3} priority={9}>{value.poznamka}</RowItem>
				{/*FIXME its not 12 size*/}
			</Row>
		</ListItem>
	);
};