import { useState } from "react";
import { TrafficSignDto } from "../../interfaces";
import { getFormattedAddress, objectStateToSortKey } from "../../tech";
import {
	getFulltextSearch,
	SORT_DIRECTION,
	sortBySortMethod,
	sortDates,
	sortNumbers,
	sortStrings,
} from "@pasport/react-common";
import { SelectOption } from "@pasport/react-ui";

export enum TRAFFIC_SIGN_SORT_METHOD {
	OZNACENI = "OZNACENI",
	ADRESA = "ADRESA",
	KOMUNIKACE = "KOMUNIKACE",
	POCET_OBJEKTU = "POCET_OBJEKTU",
	STAV = "STAV",
	DATUM_POSLEDNI_ZAVADY = "DATUM_POSLEDNI_ZAVADY",
	DATUM_POSLEDNI_UDRZBY = "DATUM_POSLEDNI_UDRZBY",
}

//FIXME this filtering is for traffic sign
export const TRAFFIC_SIGN_SORT_OPTIONS: SelectOption[] = [
	{ value: TRAFFIC_SIGN_SORT_METHOD.OZNACENI, label: "Označení" },
	{ value: TRAFFIC_SIGN_SORT_METHOD.ADRESA, label: "Ulice" },
	{ value: TRAFFIC_SIGN_SORT_METHOD.KOMUNIKACE, label: "Komunikace" },
	{ value: TRAFFIC_SIGN_SORT_METHOD.POCET_OBJEKTU, label: "Počet objektů" },
	{ value: TRAFFIC_SIGN_SORT_METHOD.STAV, label: "Stav nosiče" },
	{ value: TRAFFIC_SIGN_SORT_METHOD.DATUM_POSLEDNI_ZAVADY, label: "Datum poslední závady" },
	{ value: TRAFFIC_SIGN_SORT_METHOD.DATUM_POSLEDNI_UDRZBY, label: "Datum poslední údržby" },
];

function getSortingFunc(key: TRAFFIC_SIGN_SORT_METHOD, sortDirection: SORT_DIRECTION): (a: TrafficSignDto, b: TrafficSignDto) => number {
	const increasingOrder = sortDirection === SORT_DIRECTION.UP;
	//TODO check if it works with real data
	switch (key) {
		case TRAFFIC_SIGN_SORT_METHOD.OZNACENI:
		default:
			return (a, b) => sortStrings(a.oznaceni, b.oznaceni, increasingOrder);
		case TRAFFIC_SIGN_SORT_METHOD.ADRESA:
			return (a, b) => sortStrings(getFormattedAddress(a), getFormattedAddress(b), increasingOrder);
		case TRAFFIC_SIGN_SORT_METHOD.KOMUNIKACE:
			return (a, b) => sortStrings(a.komunikace, b.komunikace, increasingOrder);
		case TRAFFIC_SIGN_SORT_METHOD.POCET_OBJEKTU:
			return (a, b) => sortNumbers(a.pocetObjektu, b.pocetObjektu, increasingOrder);
		case TRAFFIC_SIGN_SORT_METHOD.STAV:
			return (a, b) => sortStrings(objectStateToSortKey(a.stav), objectStateToSortKey(b.stav), !increasingOrder);
		case TRAFFIC_SIGN_SORT_METHOD.DATUM_POSLEDNI_ZAVADY:
			return (a, b) => sortDates(a.datumPosledniZavady, b.datumPosledniZavady, increasingOrder);
		case TRAFFIC_SIGN_SORT_METHOD.DATUM_POSLEDNI_UDRZBY:
			return (a, b) => sortDates(a.datumPosledniUdrzby, b.datumPosledniUdrzby, increasingOrder);
	}
}


export const useTrafficSignFilter = (objects: TrafficSignDto[]) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [sortMethod, setSortMethod] = useState<TRAFFIC_SIGN_SORT_METHOD>(TRAFFIC_SIGN_SORT_METHOD.OZNACENI);
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);

	const itemsFulltext = getFulltextSearch(searchQuery, objects, item => [
		item.oznaceni,
		getFormattedAddress(item),
		item.komunikace,
	]); //FIXME some joining of ulice and cislo
	const filteredItems = sortBySortMethod(itemsFulltext, getSortingFunc(sortMethod, sortDirection));
	return {
		setSearchQuery,
		setSortMethod,
		setSortDirection,
		filteredItems,
	};
};