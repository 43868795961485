import { mapApiToTrafficSignCode } from "../TrafficSignObject/TrafficSignObjectApiMap";
import {
	apiToNumber,
	apiToString, dateToString,
	DiaryEntryDto, downloadFile,
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchDeleteFile,
	fetchEntityDetail,
	fetchEntityList,
	fetchPostAction,
	fetchUpdateEntity,
	fetchUploadFile,
	FileDto,
	FileUploadDto, LocationPoint,
	mapApiToDiaryEntry,
	mapApiToDiaryEntryAdvanced,
	mapApiToPhoto, normalizeText,
	stringToApi,
} from "@pasport/react-common";
import { mapDiaryEntryToDzApi } from "../../overrides/diary";
import { DiaryEntryAdvancedTrafficSignHorizontalApiDto } from "./TrafficSignHorizontalApiDto";
import {
	DiaryEntryAdvancedForTrafficSignHorizontal,
	TownDistrictDto,
	TrafficSignHorizontalDto,
} from "../../interfaces";
import {
	mapTrafficSignHorizontalToApi,
	mapApiToTrafficSignHorizontal,
	mapApiToMapTrafficSignHorizontal,
} from "./TrafficSignHorizontalApiMap";
import { geometryToApi } from "../../overrides/geometry/geometryApi.map";

export const createTrafficSignHorizontal = (sign: TrafficSignHorizontalDto, districtId: number) => {
	return fetchCreateEntity("insertVDZ", {
		...mapTrafficSignHorizontalToApi(sign),
		uzemi_id: districtId,
	}).then(async (singId) => {
		if (sign.geometry) {
			await updateTrafficSignHorizontalPosition({
				geometry: sign.geometry,
				tvar: sign.tvar as "point" | "linie" | "polygon",
			}, singId, districtId);
		}
		return singId;
	});
};

export const getTrafficSignHorizontalDetail = (trafficSignId: number, districtId: number) => {
	return fetchEntityDetail(`DetailVDZ/${districtId}/${trafficSignId}`, mapApiToTrafficSignHorizontal);
};

export const updateTrafficSignHorizontalDetail = (sign: TrafficSignHorizontalDto, districtId: number) => {
	return fetchPostAction("updateVDZ", {
		...mapTrafficSignHorizontalToApi(sign, true),
		vdz: sign.id,
		uzemi: districtId,
	}).then(() => {
		return updateTrafficSignHorizontalPosition(
			{
				geometry: sign.geometry,
				tvar: sign.tvar as "point" | "linie" | "polygon",
			},
			sign.id,
			districtId,
		);
	});
};

export const deleteTrafficSignHorizontal = (signId: number, districtId: number) => {
	return fetchPostAction("DeleteVDZ", {
		vdz: signId,
		uzemi: districtId,
	});
};

interface TrafficSignHorizontalPosition {
	geometry: LocationPoint[][] | null;
	tvar: "point" | "linie" | "polygon";
}

const objectTypeToApi = (objectType: "point" | "linie" | "polygon") => {
	return stringToApi(objectType === "linie" ? "multilinestring" : "MultiPolygon");
};

const getGeometryString = (geometry: string, objectType: "point" | "linie" | "polygon") => {
	if (geometry && objectType === "polygon") {
		return JSON.parse("[" + geometry + "]")
			.map((item: any) => JSON.stringify([item]))
			.join(","); //add another level
	} else {
		return geometry;
	}
};


export const updateTrafficSignHorizontalPosition = (location: TrafficSignHorizontalPosition, signId: number, districtId: number) => {
	return fetchUpdateEntity("updateSPIVDZ", {
		grafika: objectTypeToApi(location.tvar),
		geom: geometryToApi(location.geometry, location.tvar),
		//geom: stringToApi(getGeometryString(location.geometrie, location.tvar)),


		vdz: signId,
		uzemi: districtId,
	});
};

//lists
export const getTrafficSignHorizontalInDistrict = (districtId: number) => {
	return fetchEntityList(`SeznamVDZ/${districtId}`, mapApiToTrafficSignHorizontal);
};

//diaries

export const getDiaryEntriesForTrafficSignHorizontal = (signId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniVDZ/${districtId}/${signId}`, mapApiToDiaryEntry);
};

export const createDiaryEntryForTrafficSignHorizontal = async (diaryEntry: DiaryEntryDto, signId: number) => {
	return fetchCreateEntity("insertHlaseniVDZ", {
		...mapDiaryEntryToDzApi(diaryEntry, true),
		vzd: signId,
		opraveno: "0",
	});
};

export const getDiaryEntryDetailForTrafficSignHorizontal = async (diaryEntryId: number, signId: number, districtId: number) => {
	return fetchEntityDetail(`DetailHlaseniVDZ/${districtId}/${signId}/${diaryEntryId}`, mapApiToDiaryEntry);
};

export const updateDiaryEntryForTrafficSignHorizontal = async (diaryEntry: DiaryEntryDto, signId: number, districtId: number) => {
	return fetchPostAction("updateHlaseniVDZ", {
		...mapDiaryEntryToDzApi(diaryEntry, false),
		hlaseni: diaryEntry.id,
		vdz: signId,
		uzemi: districtId,
	});
};

export const deleteDiaryEntryForTrafficSignHorizontal = async (diaryEntryId: number, signId: number, districtId: number) => {
	return fetchDeleteEntity("DeleteHlaseniVDZ", {
		hlaseni: diaryEntryId,
		vdz: signId,
		uzemi: districtId,
	});
};

export const getRelatedDiaryEntriesForTrafficSignHorizontal = (parentDiaryId: number, signId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniDetailVDZ/${districtId}/${signId}/${parentDiaryId}`, mapApiToDiaryEntry);
};

export const getAllDiaryEntriesForTrafficSignHorizontal = async (): Promise<DiaryEntryAdvancedForTrafficSignHorizontal[]> => {
	return fetchEntityList(`SeznamHlaseniObecVDZ`, (response: DiaryEntryAdvancedTrafficSignHorizontalApiDto) =>
		mapApiToDiaryEntryAdvanced(response, (data) => ({
			cisloPopisne: apiToString(data.cp_co),
			kod: apiToString(data.kod_znaceni),
			komunikace: apiToString(data.komunikace),
			uzemiNazev: apiToString(data.nazev),
			objektVdzOznaceni: apiToString(data.oznaceni),
			ulice: apiToString(data.ulice),
			uzemiId: apiToNumber(data.uzemi_id),
			vdzId: apiToNumber(data.vdz_id),
		})),
	);
};

export const geTrafficSignHorizontalCodes = () => {
	return fetchEntityList(`SeznamKoduVDZ`, mapApiToTrafficSignCode);
};

//photos

export const getTrafficSignHorizontalPhotoList = async (signId: number, districtId: number) => {
	return fetchEntityList(`SeznamAttVDZ/${districtId}/${signId}`, mapApiToPhoto);
};

export const uploadTrafficSignHorizontalPhoto = async (file: FileUploadDto, signId: number) => {
	return fetchUploadFile(file, {
		vdz_id: signId,
		case: "VDZ",
	});
};

export const deleteTrafficSignHorizontalPhoto = async (file: FileDto, signId: number, districtId: number) => {
	return fetchDeleteFile("deleteAttVDZ", file, {
		vdz: signId,
		uzemi: districtId,
	});
};

//diary photos

export const getDiaryTrafficSignHorizontalPhotoList = async (diaryEntryId: number, signId: number, districtId: number) => {
	return fetchEntityList(`AttHlaseniVDZ/${districtId}/${signId}/${diaryEntryId}`, mapApiToPhoto);
};

export const uploadDiaryTrafficSignHorizontalPhoto = async (file: FileUploadDto, diaryEntryId: number) => {
	return fetchUploadFile(file, {
		VDZ: diaryEntryId,
		case: "HlaseniVDZ",
	});
};

export const deleteDiaryTrafficSignHorizontalPhoto = async (file: FileDto, diaryEntryId: number, signId: number, districtId: number) => {
	return fetchDeleteFile("deleteAttHlaseniVDZ", file, {
		hlaseni: diaryEntryId,
		vdz: signId,
		uzemi: districtId,
	});
};

export const getMapHorizontalsSigns = () => {
	return fetchEntityList("SeznamVDZmapa", mapApiToMapTrafficSignHorizontal);
};

//exports
export const downloadTrafficSignHorizontalListTable = async (district: TownDistrictDto) => {
	const fileName = `${normalizeText((district.nazev))}_vodorovne_dz_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportVDZ/${district.id}`, fileName, true);
};