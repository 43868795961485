import { TrafficSignObjectDto, TrafficSignObjectType } from "../../interfaces/TrafficSignObjectDto";
import { TrafficSignObjectCards } from "./TrafficSignObjectCards";
import { createTrafficSignObject } from "../../apis/TrafficSignObject";
import { useNavigate } from "react-router-dom";

import { FormMode, FormVersion, FormWithCreate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";

const trafficSignObjectDefault: TrafficSignObjectDto = {
	id: -1,
	oznaceni: "",
	datumAktualizace: null,
	datumInstalace: null,
	datumPorizeni: null,
	poznamka: "",
	stav: "",
	upresneniStavu: "",
	typ: TrafficSignObjectType.DOPRAVNI_ZNACKA,
	kodZnaceni: "",
	kodSlovne: "",
	oznaceniNosice: "",
	reflexni: false,
	velikostZnacky: undefined,
	zluteOramovani: false,

	datumPovoleni: null,
	povoleniPoznamka: "",

};

interface TrafficSignObjectCreateFormProps {
	districtId: number
	carrierId: number,
	onCreate: () => void
}


export const TrafficSignObjectCreateForm = ({ districtId, carrierId, onCreate }: TrafficSignObjectCreateFormProps) => {
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();

	return (
		<FormWithCreate
			initValues={trafficSignObjectDefault}
			dispatchCreate={(sign) => createTrafficSignObject(sign, carrierId, districtId)}
			onCreateSuccess={(signId: number) => {
				addSuccessAlert({ message: "Byl přidán objekt na nosiči svislého dopravního značení." });
				navigate(
					getAbsoluteRoutePath("objektSvislehoZnaceni", {
						uzemiId: districtId,
						nosicSvisleId: carrierId,
						objektSdzId: signId,
					}),
				);
				if (onCreate) onCreate();
			}}
		>
			{() => <TrafficSignObjectCards mode={FormMode.EDIT} version={FormVersion.CREATE} />}
		</FormWithCreate>
	);
};