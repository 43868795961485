import React from "react";
import { TownDistrictDto } from "../interfaces";
import { useTownDistrict } from "../components/TownDistrict/useTownDistrict";
import { TownDistrictList } from "../components/TownDistrict/TownDistrictList";

import { DiaryIcon, MapIcon } from "@pasport/react-ui";
import { hasLauTownDistricts, Page, PageHeader, SkeletonLoader, TabButton, useActiveLau } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../core/routing";


export const HomeScreen = () => {

	const { townDistrictListApiState } = useTownDistrict();
	const { activeLau } = useActiveLau();


	return (
		<SkeletonLoader
			data={[townDistrictListApiState]}
			render={([townDistricts]: [TownDistrictDto[]]) => (
				<Page>
					<div className={"container-sm px-5"}>
						<div className="d-flex justify-content-between align-items-start">
							<PageHeader title={
								hasLauTownDistricts(activeLau) ?
									"Vyberte městskou část" :
									"Vyberte katastrální území"
							} />

							<div className="">
								<TabButton label="Zobrazit mapu obce" to={getAbsoluteRoutePath("mapa", {})}
										   target={"_blank"} icon={<MapIcon />} />
								<TabButton label="Poslední hlášení" to={getAbsoluteRoutePath("vsechnaHlaseni", {})}
										   icon={<DiaryIcon />} />
							</div>
						</div>
						<TownDistrictList items={townDistricts} />
					</div>

				</Page>
			)}
		/>

	);
};
