import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TownPartDto } from "../../interfaces";
import { ApiState, createApiState } from "@pasport/react-common";

interface TownPartState {
	townParts: ApiState<TownPartDto[]>;
}


const initialState: TownPartState = {
	townParts: createApiState(),
};

const townPartSlice = createSlice({
	name: "townPart",
	initialState,
	reducers: {
		setTownParts: (state, action: PayloadAction<ApiState<TownPartDto[]>>) => {
			state.townParts = action.payload;
		},
	},

});

export const { setTownParts } = townPartSlice.actions;
export const townPartReducer = townPartSlice.reducer;