import { ClusterMarkerLayer, MapObject, Marker, useApiStateEffect } from "@pasport/react-common";
import { MapTrafficSignCarrier } from "../../apis/TrafficSignHorizontal/TrafficSignHorizontalApiDto";
import { getMapTrafficSigns } from "../../apis/TrafficSignVertical";
import CarrierClusterMarker from "../../screens/map/components/Clusters/CarrierClusterMarker";
import CarrierMarker from "../../screens/map/components/Markers/CarrierMarker";

type MapVerticalsState = {
	verticals: MapTrafficSignCarrier[];
};

export const useMapVerticals = () => {
	const [state, fetch] = useApiStateEffect<MapVerticalsState>(async () => {
		return {
			verticals: await getMapTrafficSigns(),
		};
	}, []);

	const add = (map: MapObject, data: MapVerticalsState) => {
		const layer = new ClusterMarkerLayer(
			(count) => <CarrierClusterMarker count={count} />,
			map.map
		);

		const markers: Marker[] = [];
		data.verticals.forEach((sign) => {
			const m = map.createMarker([sign.location[0], sign.location[1]], (marker, map) => (
				<CarrierMarker sign={sign} marker={marker} map={map} />
			));
			markers.push(m as Marker);
		});

		layer.add(markers);
		layer.initialize();

		return {
			verticalLayer: layer,
		};
	};

	return { apiState: state, add };
};
