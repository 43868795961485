import { RoutesParamName, RoutesParents, RoutesPaths } from "@pasport/react-common";

type SCREEN_PROPS_ZAPINACI_BOD = {
	zapinaciBodId: number
}

type SCREEN_PROPS_PRIDAT_ZB = {}

type SCREEN_PROPS_VSECHNA_HLASENI = {}

type SCREEN_PROPS_ZB_HLASENI = SCREEN_PROPS_ZAPINACI_BOD & {
	zbHlaseniId: number
}

type SCREEN_PROPS_VETEV = SCREEN_PROPS_ZAPINACI_BOD & {
	vetevId: number
}

type SCREEN_PROPS_LAMPA = SCREEN_PROPS_VETEV & {
	lampaId: number
}

type SCREEN_PROPS_SVITIDLO = SCREEN_PROPS_LAMPA & {
	svitidloId: number
}
type SCREEN_PROPS_LAMPA_HLASENI = SCREEN_PROPS_LAMPA & {
	lampaHlaseniId: number
}


type SCREEN_PROPS_PRIDAT_ZB_HLASENI = {
	zapinaciBodId: number,
	lau: number,
	hash: string,
}
type SCREEN_PROPS_PRIDAT_LAMPA_HLASENI = {
	zapinaciBodId: number,
	vetevId: number,
	lampaId: number,
	lau: number,
	hash: string,
}

type SCREEN_PROPS_UZEMI = {
	uzemiId: number
}


type SCREEN_PROPS_PREHLED_SVISLEHO_ZNACENI = SCREEN_PROPS_UZEMI & {}
type SCREEN_PROPS_PREHLED_VODOROVNEHO_ZNACENI = SCREEN_PROPS_UZEMI & {}

type SCREEN_PROPS_MAP = {}

type SCREEN_PROPS_NOSIC_SVISLE = SCREEN_PROPS_PREHLED_SVISLEHO_ZNACENI & {
	nosicSvisleId: number
}


type SCREEN_PROPS_OBJEKT_SDZ = SCREEN_PROPS_NOSIC_SVISLE & {
	objektSdzId: number
}
type SCREEN_PROPS_OBJEKT_VDZ = SCREEN_PROPS_PREHLED_VODOROVNEHO_ZNACENI & {
	objektVdzId: number
}

type SCREEN_PROPS_NOSIC_SVISLE_HLASENI = SCREEN_PROPS_NOSIC_SVISLE & {
	sdzHlaseniId: number
}
type SCREEN_PROPS_OBJEKT_VDZ_HLASENI = SCREEN_PROPS_OBJEKT_VDZ & {
	vdzHlaseniId: number
}


export type RouterProps = {
	home: undefined,
	zapinaciBod: SCREEN_PROPS_ZAPINACI_BOD,
	pridatZapinaciBod: SCREEN_PROPS_PRIDAT_ZB,
	zapinaciBodHlaseni: SCREEN_PROPS_ZB_HLASENI,
	vetev: SCREEN_PROPS_VETEV,
	lampa: SCREEN_PROPS_LAMPA,
	lampaHlaseni: SCREEN_PROPS_LAMPA_HLASENI,
	svitidlo: SCREEN_PROPS_SVITIDLO,
	pridatZapinaciBodHlaseni: SCREEN_PROPS_PRIDAT_ZB_HLASENI,
	pridatLampaHlaseni: SCREEN_PROPS_PRIDAT_LAMPA_HLASENI,
	spravovatUdaje: {},

	uzemi: SCREEN_PROPS_UZEMI

	prehledSvislehoZnaceni: SCREEN_PROPS_PREHLED_SVISLEHO_ZNACENI,
	prehledVodorovnehoZnaceni: SCREEN_PROPS_PREHLED_VODOROVNEHO_ZNACENI,

	nosicSvislehoZnaceni: SCREEN_PROPS_NOSIC_SVISLE
	objektSvislehoZnaceni: SCREEN_PROPS_OBJEKT_SDZ,
	objektVodorovnehoZnaceni: SCREEN_PROPS_OBJEKT_VDZ,

	vsechnaHlaseni: SCREEN_PROPS_VSECHNA_HLASENI,
	svisleZnaceniHlaseni: SCREEN_PROPS_NOSIC_SVISLE_HLASENI,
	vodorovneZnaceniHlaseni: SCREEN_PROPS_OBJEKT_VDZ_HLASENI,

	mapa: SCREEN_PROPS_MAP


};

export type RouterParamName = RoutesParamName<RouterProps>;
export type RouterParams = Record<RouterParamName, unknown>;

export const RouterPaths: RoutesPaths<RouterProps> = {
	home: "",
	zapinaciBod: "zapinaci-bod/:zapinaciBodId",
	pridatZapinaciBod: "pridat-zapinaci-bod",
	zapinaciBodHlaseni: "hlaseni/:zbHlaseniId",
	vetev: "vetev/:vetevId",
	lampa: "lampa/:lampaId",
	lampaHlaseni: "hlaseni/:lampaHlaseniId",
	svitidlo: "svitidlo/:svitidloId",

	pridatLampaHlaseni: "nahlaseni-vo/:lau/:zapinaciBodId/:vetevId/:lampaId/:hash",
	pridatZapinaciBodHlaseni: "nahlaseni-zb/:lau/:zapinaciBodId/:hash",
	spravovatUdaje: "udaje",

	uzemi: "uzemi/:uzemiId",

	prehledSvislehoZnaceni: "svisle-znaceni",
	prehledVodorovnehoZnaceni: "vodorovne-znaceni",

	nosicSvislehoZnaceni: "nosic/:nosicSvisleId",
	objektSvislehoZnaceni: "objekt/:objektSdzId",
	objektVodorovnehoZnaceni: "objekt/:objektVdzId",

	vsechnaHlaseni: "vsechna-hlaseni",
	mapa: "mapa",
	svisleZnaceniHlaseni: "hlaseni/:sdzHlaseniId",
	vodorovneZnaceniHlaseni: "hlaseni/:vdzHlaseniId",
};

export const RouterParents: RoutesParents<RouterProps> = {
	home: null,
	zapinaciBod: null,
	pridatZapinaciBod: null,
	zapinaciBodHlaseni: "zapinaciBod",
	vetev: "zapinaciBod",
	lampa: "vetev",
	lampaHlaseni: "lampa",
	svitidlo: "lampa",

	pridatLampaHlaseni: null,
	pridatZapinaciBodHlaseni: null,
	spravovatUdaje: null,

	uzemi: null,

	prehledSvislehoZnaceni: "uzemi",
	prehledVodorovnehoZnaceni: "uzemi",

	nosicSvislehoZnaceni: "prehledSvislehoZnaceni",
	objektSvislehoZnaceni: "nosicSvislehoZnaceni",
	objektVodorovnehoZnaceni: "prehledVodorovnehoZnaceni",
	vsechnaHlaseni: null,
	vodorovneZnaceniHlaseni: "objektVodorovnehoZnaceni",
	svisleZnaceniHlaseni: "nosicSvislehoZnaceni",
	mapa: null,
};


