import React, { useState } from "react";
import CarrierMarker from "../Markers/CarrierMarker";
import { Marker } from "maps-api";
import { MarkerPopup } from "@pasport/react-common";
import { MapTrafficSignCarrier } from "../../../../apis/TrafficSignHorizontal/TrafficSignHorizontalApiDto";
import { getAbsoluteRoutePath } from "../../../../core/routing";

interface CarrierPopupProps {
	sign: MapTrafficSignCarrier;
	open: boolean;
	marker: Marker;
}
export default function CarrierPopup({ sign, open, marker }: CarrierPopupProps) {
	const detailUrl = `${process.env.REACT_APP_SERVER_URL}/uzemi/${sign.uzemiId}/svisle-znaceni/nosic/${sign.id}/detail`;
	const seznamUrl = `${process.env.REACT_APP_SERVER_URL}/uzemi/${sign.uzemiId}/svisle-znaceni/nosic/${sign.id}/objekty`;

	return !open ? (
		<></>
	) : (
		<MarkerPopup
			marker={marker}
			title={"Označení nosiče: " + sign.oznaceni}
			subtitle="Nosič svislého dopravního značení"
			detailUrl={
				getAbsoluteRoutePath("nosicSvislehoZnaceni", {
					nosicSvisleId: sign.id,
					uzemiId: sign.uzemiId,
				}) + "/detail"
			}
		>
			<div
				style={{
					marginTop: 10,
				}}
			>
				<span className="card-subtitle text-muted ">Zobrazené značení s kódem</span>

				{sign.kodHlavniZnacky ? (
					<h6 className="card-title h6">
						<strong>
							{sign.hlavniZnackaSlovne} {`(${sign.kodHlavniZnacky})`}
						</strong>
					</h6>
				) : (
					<h6 className="card-title h6">
						<strong>Bez objektů značení</strong>
					</h6>
				)}
			</div>

			{sign.kodHlavniZnacky && (
				<a
					href={getAbsoluteRoutePath("nosicSvislehoZnaceni", {
						nosicSvisleId: sign.id,
						uzemiId: sign.uzemiId,
					})}
					target="_blank"
				>
					<button
						role="button"
						className="btn btn-link btn-sm"
						style={{
							marginLeft: -10,
							marginTop: -10,
						}}
					>
						Seznam značení
					</button>
				</a>
			)}
		</MarkerPopup>
	);

	//   return (
	//     open ? <MarkerPopup title={''} marker={new Marker}>

	//         <div style={{display:"flex", flexDirection: "row"}}>
	//             <div  style={{flex:1}}>
	//                 <span className='card-subtitle text-muted'>Nosič svislého dopravního značení</span>
	//                 <h6 className="card-title h6"><strong>Označení nosiče: {sign.oznaceni}</strong></h6>
	//             </div>
	//             <button type="button" className="close" aria-label="Close" onClick={onClose} >
	//                 <span aria-hidden="true" style={{position: "absolute", top: 5, right: 10}}>&times;</span>
	//             </button>
	//         </div>
	//         <div  style={{flex:1, display:"flex", flexDirection:"row"}} onMouseEnter={()=>{
	//             setIsOver(true)
	//         }} onMouseLeave={()=>{
	//             setIsOver(false)
	//         }}>
	//             <div >
	//                 <span className='card-subtitle text-muted ' >Zobrazené značení s kódem
	//                 </span>

	//                 {sign.kodHlavniZnacky ?
	//                     <h6 className="card-title h6"><strong>{sign.hlavniZnackaSlovne} {`(${sign.kodHlavniZnacky})`}</strong></h6>
	//                 :
	//                     <h6 className="card-title h6"><strong>Bez objektů značení</strong></h6>
	//                 }
	//             </div>
	//         </div>

	//         <div style={{display: "flex", flexDirection: "column"}}>
	//             {sign.kodHlavniZnacky && <a href={seznamUrl} target='_blank' >
	//                     <button role="button" className="btn btn-link btn-sm" style={{
	//                         marginLeft: -10,
	//                         marginTop: -20,
	//                     }}>
	//                         Seznam značení</button>
	//                 </a>}

	//             <div style={{
	//                 display:"flex",
	//                 flexDirection: "row",
	//             }}>
	//                 <div style={{
	//                     display:"flex",
	//                     flexDirection: "column",
	//                     justifyContent:"end",
	//                 }}>

	//                     <button onClick={()=>{
	//                         marker.map?.flyTo(marker.location, marker.map.getMaxZoom())
	//                     }} role="button" className="btn btn-primary btn-sm">Přiblížit</button>
	//                 </div>

	//                 <div style={{display:"flex", flexDirection: "column", alignItems:"end", justifyContent:"end", flex:1, gap:0}} >

	//                     <a href={detailUrl} target='_blank' >
	//                         <button role="button" className="btn btn-outline-primary btn-sm">
	//                             <i className="bi bi-link-45deg"></i> Otevřít detail</button>
	//                     </a>
	//                 </div>
	//             </div>

	//         </div>
	//     </MarkerPopup> : <></>
	//   )
}
