import { configureStore } from "@reduxjs/toolkit";
import { townPartReducer } from "./slices/townPartSlice";
import { apiCallsReducer } from "./slices/apiCallsSlice";


export const store = configureStore({
	reducer: {

		townPart: townPartReducer,
		apiCalls: apiCallsReducer,

	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;