export const objectStateToSortKey = (signState: string): string => {
	if (!signState)
		return "";
	const keys = ["dobrý", "uspokojivý", "špatný"];
	const pos = keys.indexOf(signState);
	if (pos >= 0) {
		return String(pos);
	} else {
		return signState;
	}
};

export const getFormattedAddress = ({ ulice, cisloPopisne }: {
	ulice?: string,
	cisloPopisne?: string
}) => {
	return [ulice, cisloPopisne].filter(Boolean).join(" ");
};