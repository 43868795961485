import { TownDistrictApiDto } from "./TownDistrictApiDto";
import { TownDistrictDto } from "../../interfaces";
import { apiToNumber, apiToString } from "@pasport/react-common";

export const mapApiToTownDistrict = (response: TownDistrictApiDto): TownDistrictDto => {

	return {
		id: apiToNumber(response.uzemi_id),
		nazev: apiToString(response.nazev_ku || response.nazev_mc),
		pocetObjektuSDZ: apiToNumber(response.pocet_sdz),
		pocetNosicuSDZ: apiToNumber(response.pocet_nosicu),
		pocetObjektuVDZ: apiToNumber(response.pocet_vdz),
	};
};