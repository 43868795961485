import React from "react";
import { MapTrafficSignHorizontal } from "../../../../apis/TrafficSignHorizontal/TrafficSignHorizontalApiDto";
import { GeometryMarker, MarkerPopup } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../../../core/routing";

interface HorizontalPopupProps {
	sign: MapTrafficSignHorizontal;
	open: boolean;
	marker: GeometryMarker;
}
export default function HorizontalPopup({ sign, open, marker }: HorizontalPopupProps) {
	const detailUrl = `${process.env.REACT_APP_SERVER_URL}/uzemi/${sign.uzemiId}/vodorovne-znaceni/objekt/${sign.id}`;

	return !open ? (
		<></>
	) : (
		<MarkerPopup
			marker={marker}
			title={"Označení objektu: " + sign.oznaceni}
			subtitle="Vodorovné dopravní značení"
			detailUrl={
				getAbsoluteRoutePath("objektVodorovnehoZnaceni", {
					objektVdzId: sign.id,
					uzemiId: sign.uzemiId,
				}) + "/detail"
			}
		>
			<div style={{ marginTop: 10 }}>
				<span className="card-subtitle text-muted">Značení s kódem</span>
				<h6 className="card-title h6">
					<strong>
						{sign.znaceniSlovne} {`(${sign.kodZnaceni})`}
					</strong>
				</h6>
			</div>
		</MarkerPopup>
	);
}
/* <div style={{display:"flex", flexDirection: "row"}}>
            <div style={{flex:1}}>
            <span className='card-subtitle text-muted'>Vodorovné dopravní značení</span>

                <h6 className="card-title h6" ><strong>Označení objektu: {sign.oznaceni}</strong></h6>
            </div>
            <button type="button" className="close" aria-label="Close" onClick={onClose} >
                <span aria-hidden="true" style={{position: "absolute", top: 5, right: 10}}>&times;</span>
            </button>
        </div>

        <div  style={{flex:1}}>
            <span className='card-subtitle text-muted'>Značení s kódem</span>
            <h6 className="card-title h6"><strong>{sign.znaceniSlovne} {`(${sign.kodZnaceni})`}</strong></h6>
        </div>


        <div style={{display: "flex", flexDirection: "row"}}>
            <button onClick={()=>{
                marker.map?.flyTo(marker.location, marker.map.getMaxZoom())
            }} role="button" className="btn btn-primary btn-sm">Přiblížit</button>
            <div style={{flex: 1}}/>
            <a href={detailUrl} target='_blank' >
                <button role="button" className="btn btn-outline-primary btn-sm">
                    <i className="bi bi-link-45deg"></i> Otevřít detail</button>
            </a>

        </div> */
