import {
	mapApiToMapTrafficSignCarrier,
	mapApiToTrafficSignSearchResult,
	mapApiToTrafficSignVertical,
	mapTrafficSignVerticalToApi,
} from "./TrafficSignVerticalApiMap";
import {
	apiToNumber,
	apiToString, dateToString,
	DiaryEntryDto, downloadFile,
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchDeleteFile,
	fetchEntityDetail,
	fetchEntityList,
	fetchPostAction,
	fetchUpdateEntity,
	fetchUploadFile,
	FileDto,
	FileUploadDto,
	mapApiToDiaryEntry,
	mapApiToDiaryEntryAdvanced,
	mapApiToPhoto,
	mapDiaryEntryToApi,
	mapPositionToRealJTSK, normalizeText,
	numberToApi,
	PositionDto,
} from "@pasport/react-common";
import { mapDiaryEntryToDzApi } from "../../overrides/diary";
import { DiaryEntryAdvancedTrafficSignApiDto } from "./TrafficSignVerticalApiDto";
import { DiaryEntryAdvancedForTrafficSignVertical, TownDistrictDto, TrafficSignVerticalDto } from "../../interfaces";

export const createTrafficSignVertical = (sign: TrafficSignVerticalDto, districtId: number) => {
	return fetchCreateEntity("insertNosicSDZ", {
		...mapTrafficSignVerticalToApi(sign),
		uzemi_id: districtId,
	}).then(async (signId) => {
		if (sign.pozice) await updateTrafficSignVerticalPosition(sign.pozice, signId, districtId);
		return signId;
	});
};

export const getTrafficSignVerticalDetail = (trafficSignId: number, districtId: number) => {
	return fetchEntityDetail(`DetailNosicSDZ/${districtId}/${trafficSignId}`, mapApiToTrafficSignVertical);
};

export const updateTrafficSignVerticalDetail = (sign: TrafficSignVerticalDto, districtId: number) => {
	return fetchUpdateEntity("updateDataNosicSDZ", {
		...mapTrafficSignVerticalToApi(sign),
		nosic: sign.id,
		uzemi: districtId,
	}).then(() => {
		return updateTrafficSignVerticalPosition(sign.pozice, sign.id, districtId);
	});
};

export const deleteTrafficSignVertical = (signId: number, districtId: number) => {
	return fetchPostAction("DeleteNosicSDZ", {
		nosic: signId,
		uzemi: districtId,
	});
};

export const updateTrafficSignVerticalPosition = (position: PositionDto | undefined, carrierId: number, districtId: number) => {
	const pos = position?.x && position?.y ? mapPositionToRealJTSK(position.x, position.y) : { x: null, y: null };
	return fetchUpdateEntity("updateSPINosicSDZ", {
		st_x: numberToApi(pos.x),
		st_y: numberToApi(pos.y),
		nosic: carrierId,
		uzemi: districtId,
	});
};

//lists
export const getTrafficSignVerticalInDistrict = (districtId: number) => {
	return fetchEntityList(`SeznamNosicSDZ/${districtId}`, mapApiToTrafficSignVertical);
};

//diaries

export const getDiaryEntriesForTrafficSignVertical = (signId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniSDZ/${districtId}/${signId}`, mapApiToDiaryEntry);
};

export const getRelatedDiaryEntriesForTrafficSignVertical = (parentDiaryId: number, carrierId: number, districtId: number) => {
	return fetchEntityList(`SeznamHlaseniDetailSDZ/${districtId}/${carrierId}/${parentDiaryId}`, mapApiToDiaryEntry);
};

export const createDiaryEntryForTrafficSignVertical = async (diaryEntry: DiaryEntryDto, signId: number) => {
	return fetchCreateEntity("insertHlaseniSDZ", {
		...mapDiaryEntryToDzApi(diaryEntry, true),
		nosic_id: signId,
		opraveno: "0",
	});
};

export const getAllDiaryEntriesForTrafficSignVertical = async (): Promise<DiaryEntryAdvancedForTrafficSignVertical[]> => {
	return fetchEntityList(`SeznamHlaseniObecSDZ`, (response: DiaryEntryAdvancedTrafficSignApiDto) =>
		mapApiToDiaryEntryAdvanced(response, (data) => ({
			nosicId: apiToNumber(data.nosic_id),
			cisloPopisne: apiToString(data.cp_co),
			komunikace: apiToString(data.komunikace),
			uzemiId: apiToNumber(data.uzemi_id),
			nosicOznaceni: apiToString(data.oznaceni),
			ulice: apiToString(data.ulice),
			uzemiNazev: apiToString(data.nazev),
			seznamKodu: apiToString(data.dz_na_nosici),
		})),
	);
};

export const getDiaryEntryDetailForTrafficSignVertical = async (diaryEntryId: number, carrierId: number, districtId: number) => {
	return fetchEntityDetail(`DetailHlaseniSDZ/${districtId}/${carrierId}/${diaryEntryId}`, mapApiToDiaryEntry);
};

export const updateDiaryEntryForTrafficSignVertical = async (diaryEntry: DiaryEntryDto, carrierId: number, districtId: number) => {
	return fetchPostAction("updateHlaseniSDZ", {
		...mapDiaryEntryToDzApi(diaryEntry, false),
		hlaseni: diaryEntry.id,
		nosic: carrierId,
		uzemi: districtId,
	});
};

export const deleteDiaryEntryForTrafficSignVertical = async (diaryEntryId: number, carrierId: number, districtId: number) => {
	return fetchDeleteEntity("DeleteHlaseniSDZ", {
		hlaseni: diaryEntryId,
		nosic: carrierId,
		uzemi: districtId,
	});
};

//photos

export const getTrafficSignVerticalPhotoList = async (carrierId: number, districtId: number) => {
	return fetchEntityList(`SeznamAttNosicSDZ/${districtId}/${carrierId}`, mapApiToPhoto);
};

export const uploadTrafficSignVerticalPhoto = async (file: FileUploadDto, carrierId: number) => {
	return fetchUploadFile(file, {
		SDZ: carrierId,
		case: "SDZ",
	});
};

export const deleteTrafficSignVerticalPhoto = async (file: FileDto, carrierId: number, districtId: number) => {
	return fetchDeleteFile("deleteNosicAttSDZ", file, {
		nosic: carrierId,
		uzemi: districtId,
	});
};

//diary photos

export const getDiaryTrafficSignVerticalPhotoList = async (diaryEntryId: number, carrierId: number, districtId: number) => {
	return fetchEntityList(`AttHlaseniSDZ/${districtId}/${carrierId}/${diaryEntryId}`, mapApiToPhoto);
};

export const uploadDiaryTrafficSignVerticalPhoto = async (file: FileUploadDto, diaryEntryId: number) => {
	return fetchUploadFile(file, {
		SDZ: diaryEntryId,
		case: "HlaseniSDZ",
	});
};

export const deleteDiaryTrafficSignVerticalPhoto = async (file: FileDto, diaryEntryId: number, carrierId: number, districtId: number) => {
	return fetchDeleteFile("deleteAttHlaseniSDZ", file, {
		hlaseni: diaryEntryId,
		nosic: carrierId,
		uzemi: districtId,
	});
};

export const getTrafficSignObjectBySearchQuery = async (search: string, districtId: number) => {
	//TODO search
	return fetchEntityList(`SeznamObjektuKU/${districtId}/${search}`, mapApiToTrafficSignSearchResult);
};

export const getMapTrafficSigns = () => {
	return fetchEntityList("SeznamSDZobec", mapApiToMapTrafficSignCarrier);
};

//exports
export const downloadTrafficSignCarrierListTable = async (district: TownDistrictDto) => {
	const fileName = `${normalizeText((district.nazev))}_nosice_svisleho_dz_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportNosic/${district.id}`, fileName, true);
};
