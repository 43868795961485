import { TrafficSignHorizontalDto } from "../../interfaces";
import { TrafficSignHorizontalItem } from "./TrafficSignHorizontalItem";
import { TrafficSignHorizontalListFilter, useTrafficSignHorizontalFilter } from "./TrafficSignHorizontalFilter";
import { Stack } from "@pasport/react-ui";
import { ListContainer, Spacing } from "@pasport/react-common";

interface TrafficSignHorizontalListProps {
	items: TrafficSignHorizontalDto[],
	getLink: (sign: TrafficSignHorizontalDto) => string
}

export const TrafficSignHorizontalList = ({ items, getLink }: TrafficSignHorizontalListProps) => {
	const { filteredItems, setSearchQuery, setSortMethod, setSortDirection } = useTrafficSignHorizontalFilter(items);
	return <>
		<TrafficSignHorizontalListFilter
			setSearchQuery={setSearchQuery}
			setSortMethod={setSortMethod}
			setSortDirection={setSortDirection}
		/>

		<Spacing space={2} />
		<ListContainer
			nextPage={100}
			items={filteredItems}
			render={(renderItems) => (
				<Stack space={2}>
					{renderItems.map(sign => <TrafficSignHorizontalItem value={sign} key={sign.id}
																		link={getLink(sign)} />)}
				</Stack>
			)}
			emptyLabel={"Filtrům neodpovídá žádný objekt vodorovného dopravního značení."}
		/>

	</>;
};