import { TRAFFIC_SIGN_SORT_METHOD, TRAFFIC_SIGN_SORT_OPTIONS } from "./useTrafficSignFilter";
import { SearchInput, Select, Stack } from "@pasport/react-ui";

import { LayoutElement, SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS, SortingContainer } from "@pasport/react-common";

interface TrafficSignListFilterProps {
	setSortDirection: (direction: SORT_DIRECTION) => void;
	setSortMethod: (sortMethod: TRAFFIC_SIGN_SORT_METHOD) => void;
	setSearchQuery: (query: string) => void;
}

export const TrafficSignListFilter = ({
										  setSearchQuery,
										  setSortMethod,
										  setSortDirection,
									  }: TrafficSignListFilterProps) => {
	return (
		<Stack direction={"row"} spaceBetween flexWrap>
			<Stack space={2} direction="row">
				<LayoutElement width={300}>
					<SearchInput placeholder={"Hledejte dle označení či umístění"} onTextChange={setSearchQuery} />
				</LayoutElement>
			</Stack>
			<SortingContainer>
				<Select
					onChange={setSortMethod as (sortMethod: string) => void}
					options={TRAFFIC_SIGN_SORT_OPTIONS}
				/>
				<Select
					onChange={setSortDirection as (sortMethod: string) => void}
					options={SORT_DIRECTION_SELECT_OPTIONS}
				/>
			</SortingContainer>
		</Stack>
	);
};