import {
	DiaryListAdvanced,
	FulltextSearchOption,
	hasLauTownDistricts, useActiveLau,
	withCommonDiaryOptions,
} from "@pasport/react-common";
import React, { useMemo } from "react";
import {
	DiaryEntryAdvancedDetailForTrafficSignHorizontal,
	DiaryEntryAdvancedForTrafficSignHorizontal,
} from "../../interfaces";
import { getAbsoluteRoutePath } from "../../core/routing";
import { getFormattedAddress } from "../../tech";

const getAdvancedDiaryLinkForHorizontalSign = (diaryEntry: DiaryEntryAdvancedForTrafficSignHorizontal) => {
	return getAbsoluteRoutePath("vodorovneZnaceniHlaseni", {
		uzemiId: diaryEntry.detail.uzemiId,
		objektVdzId: diaryEntry.detail.vdzId || 0,
		vdzHlaseniId: diaryEntry.value.id,
	});
};

const getAdvancedDiaryLabelForHorizontalSign = (diaryEntry: DiaryEntryAdvancedForTrafficSignHorizontal) => {
	const address = getFormattedAddress(diaryEntry.detail);
	const komunikace = diaryEntry.detail.komunikace;
	return (
		<>
			<strong>{diaryEntry.detail.uzemiNazev}</strong>
			{(address || komunikace) && <> / </>}
			{address && <strong>{address}</strong>}
			{address && komunikace && " • "}
			{komunikace && <strong>{diaryEntry.detail.komunikace}</strong>}
			{" - Objekt "}<strong>{diaryEntry.detail.objektVdzOznaceni}</strong>
			{diaryEntry.detail.kod && <span> ({diaryEntry.detail.kod})</span>}
		</>
	);
};

type TrafficSignAllDiaryListProps = {
	items: DiaryEntryAdvancedForTrafficSignHorizontal[]
}

export const TrafficSignHorizontalAllDiaryList = ({ items }: TrafficSignAllDiaryListProps) => {
	const { activeLau } = useActiveLau();

	const searchTrafficSignHorizontalOptions = useMemo(() => {
		const hasDistricts = hasLauTownDistricts(activeLau);
		const detailOptions = {
			"DISTRICT": {
				option: hasDistricts ? "Městská část" : "Katastrální území",
				placeholder: hasDistricts ? "Hledat dle městské části ..." : "Hledat dle katastrálního území ...",
				getSearchStrings: (diary) => {
					return [diary.detail.uzemiNazev];
				},
			},
			"ADDRESS": {
				option: "Adresa",
				placeholder: "Hledat dle adresy ...",
				getSearchStrings: (diary) => {
					return [getFormattedAddress(diary.detail)];
				},
			},
			"KOMUNIKACE": {
				option: "Komunikace",
				placeholder: "Hledat dle názvu komunikace ...",
				getSearchStrings: (diary) => {
					return [diary.detail.komunikace || ""];
				},
			},
			"OZNACENI": {
				option: "Označení",
				placeholder: "Hledat dle označení dopravního značení ...",
				getSearchStrings: (diary) => {
					return [diary.detail.objektVdzOznaceni || ""];
				},
			},
			"KOD": {
				option: "Kód značení",
				placeholder: "Hledat dle kódu značení ...",
				getSearchStrings: (diary) => {
					return [diary.detail.kod || ""];
				},
			},
		} as Record<string, FulltextSearchOption<DiaryEntryAdvancedDetailForTrafficSignHorizontal>>;

		return withCommonDiaryOptions(detailOptions);

	}, [activeLau]);

	return (
		<DiaryListAdvanced
			items={items}
			getLink={getAdvancedDiaryLinkForHorizontalSign}
			getLabel={getAdvancedDiaryLabelForHorizontalSign}
			searchOptions={searchTrafficSignHorizontalOptions}
		/>
	);
};