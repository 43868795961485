import { TownDistrictDto } from "../../interfaces";
import { TownDistrictItem } from "./TownDistrictItem";
import React from "react";
import { ListContainer } from "@pasport/react-common";
import { Stack } from "@pasport/react-ui";


interface TownDistrictListProps {
	items: TownDistrictDto[];
}

export const TownDistrictList = ({ items }: TownDistrictListProps) => {
	const filteredItems = items;
	return <>
		<ListContainer
			firstPage={100}
			items={filteredItems}
			render={(renderItems) => (
				<Stack space={2}>
					{renderItems.map(item => <TownDistrictItem district={item} />)}
				</Stack>

			)}
			emptyLabel={"Filtrům neodpovídá žádný objekt vodorovného dopravního značení."}
		/>
	</>;
};