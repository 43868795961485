import { Route, Routes } from "react-router-dom";
import { HomeScreen } from "../screens/HomeScreen";
import React from "react";
import { useTownParts } from "../hooks/useTownParts";
import { RouterPaths } from "../core/routing/routerPaths";
import { TownDistrictRouter } from "./TownDistrictRouter";
import { AllDiaryEntryListScreen } from "../screens/AllDiaryEntryListScreen";
import { useVerticalSignCodes } from "../hooks/useVerticalSignCodes";
import { useHorizontalSignCodes } from "../hooks/useHorizontalSignCodes";
import { TownPartDto, TrafficSignCodeDto } from "../interfaces";
import { TrafficSignObjectItem } from "../components/TrafficSignObject";
import { TrafficSignObjectDto, TrafficSignObjectType } from "../interfaces/TrafficSignObjectDto";
import { TrafficSignHorizontalDto } from "../interfaces/TrafficSignHorizontalDto";
import { TrafficSignHorizontalItem } from "../components/TrafficSignHorizontal/TrafficSignHorizontalItem";

import { Stack } from "@pasport/react-ui";
import {
	Breadcrumbs,
	ListContainer,
	LoginCallbackScreen,
	Page,
	PageHeader,
	Protected,
	SelectLauScreen,
	SkeletonLoader,
} from "@pasport/react-common";
import { MapScreen } from "../screens/map/MapScreen";

const defaultSign: TrafficSignObjectDto = {
	id: 6542,
	oznaceni: "47_1",
	stav: "dobrý",
	upresneniStavu: "",
	datumAktualizace: null,
	datumInstalace: null,
	datumPorizeni: null,
	kodZnaceni: "IS19a",
	kodSlovne: "",
	poznamka: "",
	typ: TrafficSignObjectType.DOPRAVNI_ZNACKA,
	zluteOramovani: false,
	reflexni: false,
	velikostZnacky: undefined,
	oznaceniNosice: "",
	datumPovoleni: null,
	povoleniPoznamka: "",
};

const trafficSignHorizontalDefault: TrafficSignHorizontalDto = {
	id: -1,
	oznaceni: "",
	cisloPopisne: "",
	datumAktualizace: null,
	datumInstalace: null,
	datumPorizeni: null,
	datumPosledniUdrzby: null,
	datumPosledniZavady: null,

	komunikace: "",
	poznamka: "",
	ulice: "",
	stav: "",
	upresneniStavu: "",
	cervenaBarva: false,
	kodZnaceni: "",
	kodSlovne: "",
	rozmer: 0,
	vodiciPruh: false,

	tvar: "polygon",
	geometrie: "",
	geometry: [],

	datumPovoleni: null,
	povoleniPoznamka: "",
};

export const AllVerticalSignsTemporaryScreen = () => {
	const { verticalSignCodes } = useVerticalSignCodes();

	const items = verticalSignCodes.map(
		(item, index) =>
			({
				...defaultSign,
				id: index,
				oznaceni: "Z_" + item.kod,
				kodZnaceni: item.kod,
				kodSlovne: item.nazev,
			}) as TrafficSignObjectDto,
	);
	return (
		<ListContainer
			nextPage={100}
			items={items}
			render={(renderItems) => (
				<Stack space={2}>
					{renderItems.map((item) => (
						<TrafficSignObjectItem key={item.id} value={item} link={""} />
					))}
				</Stack>
			)}
			emptyLabel={"Filtrům neodpovídá žádný objekt svislého dopravního značení."}
		/>
	);
};
export const AllHorizontalSignsTemporaryScreen = () => {
	const { horizontalSignCodes } = useHorizontalSignCodes();

	const items = horizontalSignCodes.map(
		(item, index) =>
			({
				...trafficSignHorizontalDefault,
				id: index,
				oznaceni: "Z_" + item.kod,
				kodZnaceni: item.kod,
				poznamka: item.nazev,
			}) as TrafficSignHorizontalDto,
	);
	return (
		<ListContainer
			nextPage={100}
			items={items}
			render={(renderItems) => (
				<Stack space={2}>
					{renderItems.map((item) => (
						<TrafficSignHorizontalItem key={item.id} value={item} link={""} />
					))}
				</Stack>
			)}
			emptyLabel={"Filtrům neodpovídá žádný objekt svislého dopravního značení."}
		/>
	);
};

export const AppRouter = () => {
	const { townPartsApiState } = useTownParts();

	const { verticalSignCodesApiState } = useVerticalSignCodes();
	const { horizontalSignCodesApiState } = useHorizontalSignCodes();
	return (
		<div className={"h-100 d-flex flex-column"}>
			<Breadcrumbs />
			<div className={"flex-grow-1"}>
				<SkeletonLoader
					data={[townPartsApiState, verticalSignCodesApiState, horizontalSignCodesApiState]}
					render={([townParts]: [TownPartDto[], TrafficSignCodeDto[], TrafficSignCodeDto[]]) => (
						<Routes>
							<Route
								path="*"
								element={
									<Protected>
										<HomeScreen />
									</Protected>
								}
							/>

							<Route
								path={RouterPaths.uzemi + "/*"}
								element={
									<Protected>
										<TownDistrictRouter />
									</Protected>
								}
							/>
							<Route
								path={RouterPaths.mapa + "/*"}
								element={
									<Protected>
										<MapScreen />
									</Protected>
								}
							/>

							<Route
								path={RouterPaths.vsechnaHlaseni + "/*"}
								element={
									<Protected>
										<AllDiaryEntryListScreen />
									</Protected>
								}
							/>

							<Route
								path={"vsechny-svisle-znacky"}
								element={
									<Page>
										<PageHeader title={"Seznam svislých značek pro testovací účely"} />
										<AllVerticalSignsTemporaryScreen />
									</Page>
								}
							/>
							<Route
								path={"vsechny-vodorovne-znacky"}
								element={
									<Page>
										<PageHeader title={"Seznam vodorovných značek pro testovací účely"} />
										<AllHorizontalSignsTemporaryScreen />
									</Page>
								}
							/>

							<Route
								path={"vyber-lau"}
								element={
									<Protected>
										<SelectLauScreen />
									</Protected>
								}
							/>
							<Route path={"login"} element={<LoginCallbackScreen />} />
						</Routes>
					)}
				/>
			</div>
		</div>
	);
};
