import { Route, Routes } from "react-router-dom";
import React from "react";
import { TownDistrictScreen } from "../screens/TownDistrictScreen";
import { RouterPaths } from "../core/routing";
import { TrafficSignVerticalRouter } from "./TrafficSignVerticalRouter";
import { TrafficSignHorizontalRouter } from "./TrafficSignHorizontalRouter";
import { useTownDistrict } from "../components/TownDistrict/useTownDistrict";
import { TownDistrictVerticalRouter } from "./TownDistrictVerticalRouter";
import { TownDistrictHorizontalRouter } from "./TownDistrictHorizontalRouter";
import { BreadcrumbSetter, hasLauTownDistricts, SkeletonLoader, useActiveLau } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";


export const TownDistrictRouter = () => {
	const { uzemiId } = useUrlParams();
	const { getTownDistrictDetail } = useTownDistrict();
	const { activeLau } = useActiveLau();
	return <SkeletonLoader
		data={[getTownDistrictDetail(uzemiId)]}
		render={([districtDetail]) => {
			return (
				<>
					<BreadcrumbSetter
						to={getAbsoluteRoutePath("uzemi", { uzemiId })}
						label={
							hasLauTownDistricts(activeLau) ?
								`Městská část ${districtDetail?.nazev}` :
								`Katastrální území ${districtDetail?.nazev}`
						}
					/>
					<Routes>
						<Route path={"*"} element={
							<TownDistrictScreen detail={districtDetail} />
						} />
						<Route path={RouterPaths.nosicSvislehoZnaceni + "/*"} element={
							<TrafficSignVerticalRouter districtDetail={districtDetail} />
						} />
						<Route path={RouterPaths.prehledSvislehoZnaceni + "/*"} element={
							<TownDistrictVerticalRouter detail={districtDetail} />
						} />
						<Route path={RouterPaths.prehledVodorovnehoZnaceni + "/*"} element={
							<TownDistrictHorizontalRouter detail={districtDetail} />
						} />
						<Route path={RouterPaths.objektVodorovnehoZnaceni + "/*"} element={
							<TrafficSignHorizontalRouter />
						} />

					</Routes>
				</>
			);
		}}
	/>;
};