import { TrafficSignHorizontalDto } from "../../interfaces";
import { TrafficSignHorizontalCards } from "./TrafficSignHorizontalCards";
import { createTrafficSignHorizontal } from "../../apis/TrafficSignHorizontal";
import { useNavigate } from "react-router-dom";
import { FormMode, FormVersion, FormWithCreate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";

const trafficSignHorizontalDefault: TrafficSignHorizontalDto = {
	id: -1,
	oznaceni: "",
	cisloPopisne: "",
	datumAktualizace: null,
	datumInstalace: null,
	datumPorizeni: null,
	datumPosledniUdrzby: null,
	datumPosledniZavady: null,

	komunikace: "",
	poznamka: "",
	ulice: "",
	stav: "",
	upresneniStavu: "",
	cervenaBarva: false,
	kodZnaceni: "",
	kodSlovne: "",
	rozmer: 0,
	vodiciPruh: false,
	tvar: "linie",//default
	geometrie: "",
	geometry: [],

	datumPovoleni: null,
	povoleniPoznamka: "",

};

interface TrafficSignHorizontalCreateFormProps {
	districtId: number;
	onCreate: () => void;
}

export const TrafficSignHorizontalCreateForm = ({ districtId, onCreate }: TrafficSignHorizontalCreateFormProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	/*TODO api calls and version CREATE*/
	return (
		<FormWithCreate
			initValues={trafficSignHorizontalDefault}
			dispatchCreate={(sign) => createTrafficSignHorizontal(sign, districtId)}
			onCreateSuccess={(signId: number) => {
				addSuccessAlert({ message: "Byl přidán objekt vodorovného dopravního značení." });
				navigate(
					getAbsoluteRoutePath("objektVodorovnehoZnaceni", {
						uzemiId: districtId, objektVdzId: signId,
					}),
				);
				if (onCreate) onCreate();
			}}
		>
			{() => <TrafficSignHorizontalCards
				mode={FormMode.EDIT}
				version={FormVersion.CREATE}
			/>}
		</FormWithCreate>
	);
};