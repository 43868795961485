import React, { useEffect, useMemo, useState } from "react";
import { Marker } from "maps-api";
import ZnaceniImage from "../ZnaceniImage";
import { MapTrafficSignCarrier } from "../../../../apis/TrafficSignHorizontal/TrafficSignHorizontalApiDto";
import CarrierPopup from "../Popups/CarrierPopup";
import { CLOSE_ALL_POPUPS_EVENT } from "@pasport/react-common";

interface CarrierMarkerProps {
	sign: MapTrafficSignCarrier;
	marker: Marker;
	map: L.Map;
}

export default function CarrierMarker({ sign, map, marker }: CarrierMarkerProps) {
	const [popupOpen, setPopupOpen] = useState(false);
	const [isOver, setIsOver] = useState(false);

	const size = 38;

	const onClick = () => {
		window.dispatchEvent(new Event(CLOSE_ALL_POPUPS_EVENT));
		setPopupOpen((o) => !o);
	};

	useEffect(() => {
		if (!marker) return;

		const onmouseover = () => {
			setIsOver(true);
		};
		const onmouseleave = () => {
			setIsOver(false);
		};

		marker.addListener("click", onClick);
		marker.addListener("mouseover", onmouseover);
		marker.addListener("mouseout", onmouseleave);

		return () => {
			marker.removeListener("click", onClick);
			marker.removeListener("mouseover", onmouseover);
			marker.removeListener("mouseout", onmouseleave);
		};
	}, [marker]);

	useEffect(() => {
		const close = () => {
			setPopupOpen(false);
		};
		window.addEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		return () => {
			window.removeEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		};
	}, []);

	const [zoom, setZoom] = useState(map.getZoom());
	useEffect(() => {
		map.on("zoomend", () => {
			setZoom(map.getZoom());
		});
	}, []);
	const levelCount = 2;

	const scaleCoef = useMemo(() => {
		let smallerCoef = 0;

		const max = map.getMaxZoom();
		if (zoom < max - levelCount) {
			smallerCoef = 0;
		} else {
			smallerCoef = zoom - (max - levelCount);
		}

		switch (smallerCoef) {
			//   case 0: return 1;
			//   case 1: return 0.6;
			//   case 2: return 0.37;
			default:
				return 1;
		}
	}, [zoom, popupOpen]);

	return (
		<div
			style={{
				transform: `translateY(-50%) translateX(-50%)`,
				width: size,
				filter: "drop-shadow(4px 4px 3px rgba(1,0,0,0.6))",
			}}
		>
			<div
				style={{
					transform: `scale(${scaleCoef * 100}%)`,
					transition: "all 0.2s ease-in-out",
				}}
			>
				<ZnaceniImage kod={sign.kodHlavniZnacky} size={size} isOver={isOver} />
			</div>

			<CarrierPopup sign={sign} open={popupOpen} marker={marker} />
		</div>
	);
}
