import { Route, Routes } from "react-router-dom";
import React from "react";
import { RouterPaths } from "../core/routing/routerPaths";
import { TrafficSignHorizontalRouter } from "./TrafficSignHorizontalRouter";
import { useTownDistrict } from "../components/TownDistrict/useTownDistrict";
import { TownDistrictHorizontalScreen } from "../screens/TownDistrictHorizontalScreen";
import { TownDistrictDto } from "../interfaces";
import { BreadcrumbSetter, SkeletonLoader } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface TownDistrictHorizontalRouterProps {
	detail: TownDistrictDto;
}

export const TownDistrictHorizontalRouter = ({ detail }: TownDistrictHorizontalRouterProps) => {
	const { uzemiId } = useUrlParams();
	const { getTownDistrictDetail } = useTownDistrict();
	return <SkeletonLoader
		data={[getTownDistrictDetail(uzemiId)]}
		render={([districtDetail]) => {
			return (
				<>
					<BreadcrumbSetter
						to={getAbsoluteRoutePath("prehledVodorovnehoZnaceni", { uzemiId })}
						label={`Vodorovné dopravní značení`}
					/>
					<Routes>
						<Route path={"*"} element={
							<TownDistrictHorizontalScreen detail={districtDetail} />
						} />
						<Route path={RouterPaths.objektVodorovnehoZnaceni + "/*"} element={
							<TrafficSignHorizontalRouter />
						} />

					</Routes>
				</>
			);
		}}
	/>;
};