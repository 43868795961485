import { Badge } from "@pasport/react-common";
import React from "react";
// "P1","P2", "A4""P1","P2", "A4"
export default function CarrierClusterMarker({ count }: { count: number }) {
	const size = 30;
	const smallerSize = size * 0.7;
	const spacing = -7;

	const [onTop, setOnTop] = React.useState(false);

	return (
		<div
			style={{
				filter: "drop-shadow(3px 3px 3px rgba(0,0,0,0.8))",
				transform: "translate(-50%, -50%)",
			}}
		>
			<div
				style={{
					width: size,
					height: size,
					borderRadius: size,
					backgroundColor: "#cf1921",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div
					style={{
						backgroundColor: "white",
						width: smallerSize,
						height: smallerSize,
						borderRadius: smallerSize,
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						opacity: 0.85,
					}}
				></div>

				<Badge
					count={count}
					transparent
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
				/>
			</div>
		</div>
	);
	//     const coef = 0.7
	//   return (
	//     <div style={{
	//         filter: "drop-shadow(3px 3px 3px rgba(0,0,0,0.8))",
	//         transform: "translate(-50%, -50%)",
	//         backgroundColor: "white",
	//         borderColor: "#0275d8",
	//         borderWidth: 0,
	//         borderStyle: "solid",
	//         width: size*coef,
	//         height: size*coef,
	//         borderRadius: size,
	//         display: "flex",
	//         justifyContent: "center",
	//         alignItems: "center",
	//     }}
	//     onMouseOver={()=>setOnTop(true)}
	//     onMouseLeave={()=>setOnTop(false)}>
	//         <div style={{
	//             display: "flex",
	//             flexDirection: "column",
	//             justifyContent: "center",
	//             alignItems: "center",

	//         }}>
	//             <div style={{
	//                 display: "flex",
	//                 flexDirection: "row",
	//             }}>
	//                  <ZnaceniImage kod='A4' size={signSize} style={{
	//                         marginRight: -signSize*0.1 + spacing,

	//                  }}/>
	//                  <ZnaceniImage kod='IP2' size={signSize}/>
	//             </div>
	//             <ZnaceniImage kod='P2' size={signSize} style={{
	//                 marginTop: -signSize*0.1 + spacing,
	//             }}/>
	//         </div>

	//         <Badge count={count} style={{
	//             position:"absolute",
	//             top: "50%",
	//             left: "50%",
	//             transform: "translate(-50%, -50%)",
	//             opacity: onTop? 1 : 0,
	//             transition: "all 0.2s ease-in-out"
	//         }}/>

	//     </div>
	//   )
}
