import { Route, Routes } from "react-router-dom";
import React from "react";
import { RouterPaths } from "../core/routing";
import { TrafficSignVerticalRouter } from "./TrafficSignVerticalRouter";
import { useTownDistrict } from "../components/TownDistrict/useTownDistrict";
import { TownDistrictVerticalScreen } from "../screens/TownDistrictVerticalScreen";
import { TownDistrictDto } from "../interfaces";
import { BreadcrumbSetter, SkeletonLoader } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";


interface TownDistrictVerticalRouterProps {
	detail: TownDistrictDto;
}

export const TownDistrictVerticalRouter = ({ detail }: TownDistrictVerticalRouterProps) => {
	const { uzemiId } = useUrlParams();
	const { getTownDistrictDetail } = useTownDistrict();
	return <SkeletonLoader
		data={[getTownDistrictDetail(uzemiId)]}
		render={([districtDetail]) => {
			return (
				<>
					<BreadcrumbSetter
						to={getAbsoluteRoutePath("prehledSvislehoZnaceni", { uzemiId })}
						label={`Svislé dopravní značení`}
					/>
					<Routes>
						<Route path={"*"} element={
							<TownDistrictVerticalScreen detail={districtDetail} />
						} />
						<Route path={RouterPaths.nosicSvislehoZnaceni + "/*"} element={
							<TrafficSignVerticalRouter districtDetail={detail} />
						} />

					</Routes>
				</>
			);
		}}
	/>;
};