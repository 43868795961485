import { TrafficSignHorizontalList } from "../components/TrafficSignHorizontal";
import { TrafficSignHorizontalCreateForm } from "../components/TrafficSignHorizontal/TrafficSignHorizontalCreateForm";
import {
	downloadTrafficSignHorizontalListTable,
	getTrafficSignHorizontalInDistrict,
} from "../apis/TrafficSignHorizontal";
import { TownDistrictDto, TrafficSignHorizontalDto } from "../interfaces";

import { AddIcon, DownloadIcon, ListIcon } from "@pasport/react-ui";
import { Page, PageHeader, SkeletonLoader, TabContent, TabRoutes, useApiStateEffect } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { DownloadTableTile } from "../common/DownloadTableTile";
import React from "react";

interface TownDistrictHorizontalScreenProps {
	detail: TownDistrictDto;
}

export const TownDistrictHorizontalScreen = ({ detail }: TownDistrictHorizontalScreenProps) => {
	const { uzemiId } = useUrlParams();

	const [horizontalSignsApiState, invalidateHorizontalSignList] = useApiStateEffect(() => getTrafficSignHorizontalInDistrict(uzemiId));
	return (

		<SkeletonLoader
			data={[horizontalSignsApiState]}
			render={([horizontalTrafficSignList]: [TrafficSignHorizontalDto[]]) => (
				<Page>
					<PageHeader title={`Vodorovné dopravní značení - ${detail.nazev}`} />
					<TabRoutes links={[{
						to: "seznam",
						label: "Seznam objektů vodorovného dopravního značení",
						icon: <ListIcon />,
						content: (
							<TabContent
								title={"Vodorovné objekty dopravního značení"}
								isEmpty={horizontalTrafficSignList.length === 0}
								emptyLabel={"Zatím zde není žádný objekt vodorovného dopravního značení."}
							>
								<TrafficSignHorizontalList
									items={horizontalTrafficSignList}
									getLink={sign => getAbsoluteRoutePath("objektVodorovnehoZnaceni", {
										uzemiId,
										objektVdzId: sign.id,
									})}
								/>
							</TabContent>

						),
					}]}

							   actionLinks={[{
								   to: "pridat-objekt",
								   label: "Přidat objekt VDZ",
								   icon: <AddIcon />,
								   content: (
									   <TabContent title={"Přidat objekt vodorovného dopravního značení"}>
										   <TrafficSignHorizontalCreateForm districtId={uzemiId}
																			onCreate={invalidateHorizontalSignList} />
									   </TabContent>
								   ),

							   }, {
								   to: "exportovat",
								   label: "Exportovat",
								   icon: <DownloadIcon />,
								   content: (
									   <DownloadTableTile
										   onDownload={() => downloadTrafficSignHorizontalListTable(detail)}
										   cardTitle={"Seznam objektů vodorovného dopravního značení"}
										   buttonLabel={"Stáhnout tabulku objektů vodorovného dopravního značení"}
									   />

								   ),
							   }]}
					/>

				</Page>
			)}
		/>

	);
};