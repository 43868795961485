import { TrafficSignObjectDto, TrafficSignObjectType } from "../../interfaces/TrafficSignObjectDto";
import { useField } from "formik";
import { useVerticalSignCodes } from "../../hooks/useVerticalSignCodes";
import { DetailCard, DetailColumn, DetailItemRow, DetailRow } from "@pasport/react-ui";

import { createForm, FormMode, FormVersion, OptionItem } from "@pasport/react-common";

const Form = createForm<TrafficSignObjectDto>();

interface TrafficSignCardsProps {
	mode: FormMode,
	version: FormVersion
}

export const TrafficSignObjectCards = ({ mode, version }: TrafficSignCardsProps) => {
	const [typField] = useField<TrafficSignObjectType>("typ");
	const { verticalSignCodes } = useVerticalSignCodes();
	//TODO refactor cards content, to more cards
	return (
		<DetailRow>
			<DetailColumn>
				<DetailCard title="Základní informace">
					<div>
						<Form.TextItem name={"oznaceni"} label={"Označení"} mode={mode} required />


						<DetailItemRow>
							<Form.EnumItem
								name={"typ"}
								label={"Typ"}
								mode={mode}
								options={["Dopravní značka", "Světelné signalizační zařízení", "Dopravní zrcadlo", "Detektor rychlosti"]}
								required
							/>
						</DetailItemRow>

						{typField.value === "Dopravní značka" && (
							<DetailItemRow>
								<Form.EnumItem
									name={"kodZnaceni"}
									label={"Kód značení"}
									mode={mode}
									required
									options={[
										null,
										...verticalSignCodes.map(item => ({
											value: item.kod,
											label: `( ${item.kod} ) ${item.nazev}`,
										} as OptionItem)),
									]}
								/>
								{/*<Form.TextItem name={"kodSlovne"} label={"Kód slovně"} mode={mode} readonly/>*/}
							</DetailItemRow>
						)}

						<DetailItemRow>
							<Form.TextItem name={"oznaceniNosice"} label={"Označení nosiče"} mode={mode} readonly />
						</DetailItemRow>
						<DetailItemRow>
							<Form.EnumItem name={"stav"} label={"Stav objektu"} mode={mode}
										   options={[null, "dobrý", "uspokojivý", "špatný"]} />
							<Form.TextItem name={"upresneniStavu"} label={"Upřesnění stavu"} mode={mode} />
						</DetailItemRow>
						{typField.value === "Dopravní značka" && (
							<DetailItemRow>
								<Form.EnumItem name={"velikostZnacky"} label={"Velikost značky"} mode={mode}
											   options={[null, "malá", "střední", "velká", "nestandartní"]} />
								<Form.BooleanItem name={"reflexni"} label={"Reflexní"}
												  mode={mode} /> {/*Boolean value*/}
								<Form.BooleanItem name={"zluteOramovani"} label={"Žluté orámování"}
												  mode={mode} /> {/*Boolean value*/}
							</DetailItemRow>
						)}
						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>
			</DetailColumn>
			<DetailColumn>
				<DetailCard title="Data">
					<div>
						<DetailItemRow>
							<Form.DateItem name={"datumInstalace"} label={"Datum instalace objektu"} mode={mode} />
							<Form.DateItem name={"datumPorizeni"} label={"Datum pořízení v terénu"} mode={mode} />
							<Form.DateItem name={"datumAktualizace"} label={"Datum aktualizace v terénu"} mode={mode} />
						</DetailItemRow>

					</div>
				</DetailCard>
				<DetailCard title="Povolení">
					<Form.DateItem name={"datumPovoleni"} label={"Datum povolení"} mode={mode} />
					<Form.TextareaItem name={"povoleniPoznamka"} label={"Informace o povolení"} mode={mode} />
				</DetailCard>
			</DetailColumn>
		</DetailRow>
	);
};
