export function useCodeImage() {
	const getSVGPath = (code: string) => {
		code = HARDCORDER_ALIASES[code] || code;
		const url = `znacky/${code}.svg`;
		return url;
	};
	return {
		getSVGPath,
	};
}

const HARDCORDER_ALIASES: Record<string, string> = {
	"Detektor rychlosti": "radar",
	"Dopravní zrcadlo": "zrcadlo",
	"Světelné signalizační zařízení": "samafor",
};
