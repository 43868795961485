import { TownDistrictDto } from "../../interfaces";

import { Icon, ListItem, Row, RowItem } from "@pasport/react-ui";
import { getAbsoluteRoutePath } from "../../core/routing";


interface TownDistrictItemProps {
	district: TownDistrictDto,
}

export const TownDistrictItem = ({ district }: TownDistrictItemProps) => {
	const link = getAbsoluteRoutePath("uzemi", { uzemiId: district.id });//"/uzemi/"+district.id;
	return (
		<ListItem link={link}
				  icon={<span className={"fs-2"}><Icon type={"bi-geo-alt-fill"} /></span>/*FIXME size and icon fix*/}>
			<Row>
				<RowItem label={""} size={4.5} priority={10}>{district.nazev}</RowItem>
				<RowItem label={"Počet nosičů svislého DZ"} size={2.5} priority={10}>{district.pocetNosicuSDZ}</RowItem>
				<RowItem label={"Počet objektů svislého DZ"} size={2.5}>{district.pocetObjektuSDZ}</RowItem>
				<RowItem label={"Počet objektů vodorovného DZ"} size={2.5}
						 priority={9}>{district.pocetObjektuVDZ}</RowItem>
				{/*FIXME its not 12 size*/}
			</Row>
		</ListItem>
	);
};
