import { Route, Routes } from "react-router-dom";
import { RouterPaths } from "../core/routing/routerPaths";
import React from "react";
import { getTrafficSignHorizontalDetail } from "../apis/TrafficSignHorizontal";
import { TrafficSignHorizontalScreen } from "../screens/TrafficSignHorizontalScreen";
import { TrafficSignHorizontalDiaryScreen } from "../screens/TrafficSignHorizontalDiaryScreen";
import { BreadcrumbSetter, SkeletonLoader, useApiStateEffect } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

export const TrafficSignHorizontalRouter = () => {

	const { uzemiId, objektVdzId } = useUrlParams();


	const [signDetailApiState] = useApiStateEffect(() => getTrafficSignHorizontalDetail(objektVdzId, uzemiId));

	return <SkeletonLoader
		data={[signDetailApiState]}
		render={([signDetail]) => (
			<>
				<BreadcrumbSetter
					to={getAbsoluteRoutePath("objektVodorovnehoZnaceni", { uzemiId, objektVdzId })}
					label={`Objekt vodorovného značení ${signDetail.oznaceni}`}
				/>
				<Routes>
					<Route path={"*"} element={
						<TrafficSignHorizontalScreen signDetail={signDetail} />
					} />
					<Route path={RouterPaths.vodorovneZnaceniHlaseni + "/*"} element={
						<TrafficSignHorizontalDiaryScreen signDetail={signDetail} />
					} />

				</Routes>
			</>
		)}
	/>;
};