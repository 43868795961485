import { Route, Routes } from "react-router-dom";
import { RouterPaths } from "../core/routing/routerPaths";
import { TrafficSignVerticalScreen } from "../screens/TrafficSignVerticalScreen";
import React from "react";
import { getTrafficSignVerticalDetail } from "../apis/TrafficSignVertical";
import { TrafficSignObjectScreen } from "../screens/TrafficSignObjectScreen";
import { TrafficSignVerticalDiaryScreen } from "../screens/TrafficSignVerticalDiaryScreen";
import { BreadcrumbSetter, SkeletonLoader, useApiStateEffect } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { TownDistrictDto } from "../interfaces";

type TrafficSignVerticalRouterProps = {
	districtDetail: TownDistrictDto;
}

export const TrafficSignVerticalRouter = ({ districtDetail }: TrafficSignVerticalRouterProps) => {

	const { uzemiId, nosicSvisleId } = useUrlParams();

	const [detailApiState] = useApiStateEffect(() => getTrafficSignVerticalDetail(nosicSvisleId, uzemiId));

	return <SkeletonLoader
		data={[detailApiState]}
		render={([signDetail]) => (
			<>
				<BreadcrumbSetter
					to={getAbsoluteRoutePath("nosicSvislehoZnaceni", { uzemiId, nosicSvisleId })}
					label={`Nosič svislého značení ${signDetail.oznaceni}`}
				/>
				<Routes>
					<Route path={"*"} element={
						<TrafficSignVerticalScreen detail={signDetail} districtDetail={districtDetail} />
					} />
					<Route path={RouterPaths.objektSvislehoZnaceni + "/*"} element={
						<TrafficSignObjectScreen />
					} />
					<Route path={RouterPaths.svisleZnaceniHlaseni + "/*"} element={
						<TrafficSignVerticalDiaryScreen signDetail={signDetail} />
					} />

				</Routes>
			</>
		)}
	/>;
};
