import { TrafficSignCodeDto } from "../interfaces";
import { useEffect } from "react";
import { useReduxApiState } from "../redux";
import { geTrafficSignHorizontalCodes } from "../apis/TrafficSignHorizontal";
import { isApiStateDispatched } from "@pasport/react-common";

type ObjectType = "point" | "linie" | "polygon";

export const useHorizontalSignCodes = () => {
	const { fetchApiState, apiState } = useReduxApiState<TrafficSignCodeDto[]>("horizontalSignCodes");

	useEffect(() => {
		if (!isApiStateDispatched(apiState))
			fetchApiState(() => geTrafficSignHorizontalCodes());
	}, [apiState]);

	const getHorizontalSignCodeType = (signCode: string): ObjectType => {
		return apiState.data?.find(item => item.kod === signCode)?.tvar as ObjectType || "polygon";
	};

	return {
		horizontalSignCodesApiState: apiState,
		horizontalSignCodes: apiState.data || [],
		getHorizontalSignCodeType,
	};
};