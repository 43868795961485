import { TownDistrictDto } from "../interfaces";
import { ReactNode } from "react";

import { CardDivider, CardSection, ListItem, Stack } from "@pasport/react-ui";
import { hasLauTownDistricts, Page, PageHeader, useActiveLau } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface TownDistrictScreenProps {
	detail: TownDistrictDto;
}


interface SignTypeButtonProps {
	title: string,
	link: string,
	description?: ReactNode
}

interface DataItemProps {
	label: string,
	value: string | undefined | number
}

const DataItem = ({ label, value }: DataItemProps) => {
	return (
		<div className="d-flex flex-column w-100">
			<div className="small">{label}</div>
			<div className="fw-bold">{value !== undefined ? value : "—"}</div>
		</div>
	);

};

const TrafficSignTypeButton = ({ title, link, description }: SignTypeButtonProps) => {

	return (

		<ListItem link={link} extraContent={description ?
			<>
				<CardDivider />
				<CardSection>
					{description}
				</CardSection>
			</> : undefined
		}>
			<span className={"fs-5 fw-bold"}>{title}</span>
		</ListItem>
	);
};


export const TownDistrictScreen = ({ detail }: TownDistrictScreenProps) => {
	const { uzemiId } = useUrlParams();
	const { activeLau } = useActiveLau();

	return (
		<Page>
			<PageHeader title={
				hasLauTownDistricts(activeLau) ?
					`Městská část ${detail?.nazev}` :
					`Katastrální území ${detail?.nazev}`
			} />

			<Stack center direction={"column"} className={"justify-content-center p-2"}>
				<h3 className="text-center lh-1 mt-5"><span>Vyberte kategorii</span></h3>
				<div style={{ width: "100%", maxWidth: 800 }}>
					<Stack center direction={"row"} className={"justify-content-center p-2"}>
						<div className={"w-100"}>

							<TrafficSignTypeButton
								title={"Svislé dopravní značení"}
								link={getAbsoluteRoutePath("prehledSvislehoZnaceni", { uzemiId })}
								description={
									<Stack direction={"row"}>
										<DataItem label="Počet nosičů" value={detail.pocetNosicuSDZ} />
										<DataItem label="Počet objektů" value={detail.pocetObjektuSDZ} />
									</Stack>
								}
							/>
						</div>
						<div className={"w-100"}>
							<TrafficSignTypeButton
								title={"Vodorovné dopravní značení"}
								link={getAbsoluteRoutePath("prehledVodorovnehoZnaceni", { uzemiId })}
								description={
									<Stack direction={"row"}>
										<DataItem label="Počet objektů" value={detail.pocetObjektuVDZ} />
									</Stack>
								}
							/>
						</div>

					</Stack>
				</div>
			</Stack>
		</Page>
	);
};