import React from "react";
import {
	TrafficSignHorizontalEditableDetail,
} from "../components/TrafficSignHorizontal/TrafficSignHorizontalEditableDetail";
import {
	createDiaryEntryForTrafficSignHorizontal,
	getDiaryEntriesForTrafficSignHorizontal,
} from "../apis/TrafficSignHorizontal";
import { TrafficSignHorizontalDto } from "../interfaces";
import { useNavigate } from "react-router-dom";

import { AddIcon, DetailIcon } from "@pasport/react-ui";
import {
	DiaryEntryCreateForm, DiaryEntryDto,
	DiaryList,
	Page,
	PageHeader,
	SkeletonLoader,
	TabContent,
	TabRoutes,
	useAlert,
	useApiStateEffect,
} from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface TrafficSignHorizontalScreenProps {
	signDetail: TrafficSignHorizontalDto;
}

export const TrafficSignHorizontalScreen = ({ signDetail }: TrafficSignHorizontalScreenProps) => {
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();
	const { uzemiId, objektVdzId } = useUrlParams();
	const [diaryEntriesApiState, invalidateDiaryEntries] = useApiStateEffect(() => getDiaryEntriesForTrafficSignHorizontal(objektVdzId, uzemiId));


	return (
		<SkeletonLoader data={[diaryEntriesApiState]} render={([diaryEntriesApiState]: [DiaryEntryDto[]]) => (
			<Page>
				<>
					<PageHeader title={`Objekt vodorovného dopravního značení ${signDetail.oznaceni}`} />
					<TabRoutes
						links={[{
							to: "detail",
							label: "Detail objektu",
							icon: <DetailIcon />,
							content: (
								<TabContent title={"O objektu vodorovného značení"}>
									<TrafficSignHorizontalEditableDetail detail={signDetail} districtId={uzemiId} />
								</TabContent>
							),
						}, {
							to: "denik",
							label: "Deník vodorovného značení",
							icon: <DetailIcon />,
							content: (
								<DiaryList
									items={diaryEntriesApiState}
									getLink={(diary) => getAbsoluteRoutePath("vodorovneZnaceniHlaseni", {
										uzemiId,
										objektVdzId,
										vdzHlaseniId: diary.id,
									})}

								/> //FIXME rename to TabDiaryList
							),
						}]}
						actionLinks={[{
							to: "pridat-denik",
							label: "Přidat deníkový záznam",
							icon: <AddIcon />,
							content: (
								<TabContent title={"Přidat deníkový záznam"}>
									<DiaryEntryCreateForm
										dispatchCreate={(diaryEntry) => createDiaryEntryForTrafficSignHorizontal(diaryEntry, objektVdzId)}
										onCreateSuccess={(diaryEntryId: number) => {
											addSuccessAlert({ message: "K objektu vodorovnému značení bylo přidáno deníkové hlášení." });
											navigate(
												getAbsoluteRoutePath("vodorovneZnaceniHlaseni", {
													uzemiId,
													objektVdzId,
													vdzHlaseniId: diaryEntryId,
												}),
											);
											invalidateDiaryEntries();
										}}
									/>
								</TabContent>
							),
						}]}
					/>
				</>

			</Page>
		)} />

	);
};