import { TrafficSignCodeDto } from "../interfaces";
import { useEffect } from "react";
import { useReduxApiState } from "../redux";
import { geTrafficSignVerticalCodes } from "../apis/TrafficSignObject";
import { isApiStateDispatched } from "@pasport/react-common";

export const useVerticalSignCodes = () => {
	const { fetchApiState, apiState } = useReduxApiState<TrafficSignCodeDto[]>("verticalSignCodes");

	useEffect(() => {
		if (!isApiStateDispatched(apiState))
			fetchApiState(() => geTrafficSignVerticalCodes());
	}, [apiState]);

	return {
		verticalSignCodesApiState: apiState,
		verticalSignCodes: apiState.data || [],
	};
};