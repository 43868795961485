import { TrafficSignHorizontalDto } from "../../interfaces";
import { TrafficSignHorizontalCards } from "./TrafficSignHorizontalCards";
import {
	deleteTrafficSignHorizontal,
	deleteTrafficSignHorizontalPhoto,
	getTrafficSignHorizontalPhotoList,
	updateTrafficSignHorizontalDetail,
	uploadTrafficSignHorizontalPhoto,
} from "../../apis/TrafficSignHorizontal";
import { useNavigate } from "react-router-dom";

import { FormVersion, FormWithUpdate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";


interface TrafficSignHorizontalEditableDetailProps {
	detail: TrafficSignHorizontalDto,
	districtId: number
}

export const TrafficSignHorizontalEditableDetail = ({
														detail,
														districtId,
													}: TrafficSignHorizontalEditableDetailProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();

	//TODO apiState for update and delete
	return (
		<FormWithUpdate
			initValues={detail}
			dispatchUpdate={(updated: TrafficSignHorizontalDto) => updateTrafficSignHorizontalDetail(updated, districtId)}
			dispatchDelete={() => deleteTrafficSignHorizontal(detail.id, districtId)}
			onUpdateSuccess={() => addSuccessAlert({ message: "Objekt vodorovného dopravního značení byl upraven." })}
			onDeleteSuccess={() => {
				addSuccessAlert({ message: "Objekt vodorovného dopravního značení byl odstraněn." });
				navigate(getAbsoluteRoutePath("uzemi", { uzemiId: districtId /*TODO navigate to correct tab*/ }));
			}}
		>
			{({ mode }) => (
				<TrafficSignHorizontalCards
					mode={mode} version={FormVersion.UPDATE}
					photoApi={{
						getList: () => getTrafficSignHorizontalPhotoList(detail.id, districtId),
						upload: (file) => uploadTrafficSignHorizontalPhoto(file, detail.id),
						delete: (file) => deleteTrafficSignHorizontalPhoto(file, detail.id, districtId),
					}}
				/>
			)}
		</FormWithUpdate>
	);
};