import { TrafficSignList } from "../components/TrafficSign/TrafficSignList";
import { TrafficSignCreateForm } from "../components/TrafficSign";
import { downloadTrafficSignCarrierListTable, getTrafficSignVerticalInDistrict } from "../apis/TrafficSignVertical";
import { TrafficSignVerticalApiDto } from "../apis/TrafficSignVertical/TrafficSignVerticalApiDto";
import { TownDistrictDto } from "../interfaces";
import { TrafficSignObjectSearchModal } from "../components/TrafficSignObject/TrafficSignObjectSearchModal";
import React, { useState } from "react";

import { AddIcon, DownloadIcon, ListIcon, SearchIcon } from "@pasport/react-ui";
import {
	ApiState,
	Page,
	PageHeader,
	SkeletonLoader,
	TabContent,
	TabRoutes,
	useApiStateEffect,
} from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { DownloadTableTile } from "../common/DownloadTableTile";

interface TownDistrictVerticalScreenProps {
	detail: TownDistrictDto;
}

export const TownDistrictVerticalScreen = ({ detail }: TownDistrictVerticalScreenProps) => {
	const { uzemiId } = useUrlParams();
	const [visibleSearch, setVisibleSearch] = useState(false);

	const [verticalSignsApiState, invalidateVerticalSignList] = useApiStateEffect(() => getTrafficSignVerticalInDistrict(uzemiId));

	return (

		<SkeletonLoader
			data={[verticalSignsApiState as unknown as ApiState<TrafficSignVerticalApiDto[]>]}
			render={([verticalTrafficSignList]: [TrafficSignVerticalApiDto[]]) => (
				<Page>
					<PageHeader title={`Svislé dopravní značení - ${detail.nazev}`} />
					<TabRoutes links={[{
						to: "seznam",
						label: "Seznam nosičů svislého dopravního značení",
						icon: <ListIcon />,
						content: (
							<TabContent
								title={"Nosiče svislého dopravního značení"}
								isEmpty={verticalTrafficSignList.length === 0}
								emptyLabel={"Zatím zde není žádný nosič svislého dopravního značení."}
							>
								<TrafficSignList
									items={verticalTrafficSignList}
									getLink={sign => getAbsoluteRoutePath("nosicSvislehoZnaceni", {
										uzemiId,
										nosicSvisleId: sign.id,
									})}
								/>
							</TabContent>
						),
					}]}

							   actionLinks={[{
								   onPress: () => setVisibleSearch(true),
								   label: "Vyhledat objekt",
								   icon: <SearchIcon />,
								   content: null,
							   },
								   {
									   to: "pridat-nosic",
									   label: "Přidat nosič SDZ",
									   icon: <AddIcon />,
									   content: (
										   <TabContent title={"Přidat nosič svislého dopravního značení"}>
											   <TrafficSignCreateForm districtId={uzemiId}
																	  onCreate={invalidateVerticalSignList} />
										   </TabContent>
									   ),

								   },
								   {
									   to: "exportovat",
									   label: "Exportovat",
									   icon: <DownloadIcon />,
									   content: (
										   <DownloadTableTile
											   onDownload={() => downloadTrafficSignCarrierListTable(detail)}
											   cardTitle={"Seznam nosičů svislého dopravního značení"}
											   buttonLabel={"Stáhnout tabulku nosičů svislého dopravního značení"}
										   />

									   ),
								   },
							   ]}
					/>
					<TrafficSignObjectSearchModal visible={visibleSearch} onClose={() => setVisibleSearch(false)} />
				</Page>
			)}
		/>

	);
};