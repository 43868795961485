
export enum TrafficSignObjectType {
    DOPRAVNI_ZNACKA = 'Dopravní značka',
    SVETELNA_SIGNALIZACE = 'Světelné signalizační zařízení',
    DOPRAVNI_ZRCADLO = 'Dopravní zrcadlo',
    DETEKTOR_RYCHLOSTI = 'Detektor rychlosti'
}

export type TrafficSignObjectSize = 'malá' | 'střední' | 'velká' | 'nestandardní'

export interface TrafficSignObjectDto {
    id: number,
    oznaceni: string,
    typ: TrafficSignObjectType,
    kodZnaceni: string, //from list,
    kodSlovne: string, //  needitovatelny, skutecne to chceme
    oznaceniNosice: string,
    stav: string,
    upresneniStavu: string,
    velikostZnacky?: TrafficSignObjectSize;
    reflexni: boolean,
    zluteOramovani: boolean,

    datumInstalace: Date | null,
    datumPorizeni: Date | null,
    datumAktualizace: Date | null,
    poznamka: string,



    datumPovoleni: Date | null,
    povoleniPoznamka: string,
}