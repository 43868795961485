import { TrafficSignObjectDto } from "../../interfaces/TrafficSignObjectDto";
import { TrafficSignObjectCards } from "./TrafficSignObjectCards";
import { deleteTrafficSignObject, updateTrafficSignObjectDetail } from "../../apis/TrafficSignObject";
import { useNavigate } from "react-router-dom";

import { FormVersion, FormWithUpdate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";


interface TrafficSignObjectEditableDetailProps {
	detail: TrafficSignObjectDto,
	carrierId: number,
	districtId: number
}

export const TrafficSignObjectEditableDetail = ({
													detail,
													carrierId,
													districtId,
												}: TrafficSignObjectEditableDetailProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();

	return (
		<FormWithUpdate
			initValues={detail}
			dispatchUpdate={(sign) => updateTrafficSignObjectDetail(sign, carrierId, districtId)}
			dispatchDelete={() => deleteTrafficSignObject(detail.id, carrierId, districtId)}
			onUpdateSuccess={() => addSuccessAlert({ message: "Objekt svislého dopravního značení byl upraven." })}
			onDeleteSuccess={() => {
				addSuccessAlert({ message: "Objekt svislého dopravního značení byl odstraněn." });
				navigate(getAbsoluteRoutePath("nosicSvislehoZnaceni", {
					uzemiId: districtId,
					nosicSvisleId: carrierId,
				}));
			}}
		>
			{({ mode }) => (
				<TrafficSignObjectCards mode={mode} version={FormVersion.UPDATE} />
			)}
		</FormWithUpdate>
	);
};