import { CLOSE_ALL_POPUPS_EVENT, GeometryMarker } from "@pasport/react-common";
import React, { useEffect, useMemo, useState } from "react";
import { MapTrafficSignHorizontal } from "../../../../apis/TrafficSignHorizontal/TrafficSignHorizontalApiDto";
import ZnaceniImage from "../ZnaceniImage";
import HorizontalPopup from "../Popups/HorizontalPopup";

interface HorizontalMarkerProps {
	sign: MapTrafficSignHorizontal;
	marker: GeometryMarker;
	map: L.Map;
}

export default function HorizontalMarker({ sign, map, marker }: HorizontalMarkerProps) {
	const [popupOpen, setPopupOpen] = useState(false);

	const [isOver, setIsOver] = useState(false);

	const onClick = () => {
		window.dispatchEvent(new Event(CLOSE_ALL_POPUPS_EVENT));
		setPopupOpen((o) => !o);
	};

	useEffect(() => {
		const close = () => {
			setPopupOpen(false);
		};
		window.addEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		return () => {
			window.removeEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		};
	}, []);

	const [zoom, setZoom] = useState(map.getZoom());

	useEffect(() => {
		if (!marker) return;

		const onmouseover = () => {
			setIsOver(true);
		};
		const onmouseleave = () => {
			setIsOver(false);
		};

		marker.addListener("click", onClick);
		marker.addListener("mouseover", onmouseover);
		marker.addListener("mouseout", onmouseleave);

		return () => {
			marker.removeListener("click", onClick);
			marker.removeListener("mouseover", onmouseover);
			marker.removeListener("mouseout", onmouseleave);
		};
	}, [marker]);

	useEffect(() => {
		const focused = isOver || popupOpen;
		marker.getLeafletObject().setStyle({
			color: focused ? "red" : "blue",
			weight: focused ? 8 : 5,
		});
	}, [isOver, popupOpen]);

	const levelCount = 2;

	const scaleCoef = useMemo(() => {
		let smallerCoef = 0;

		const max = map.getMaxZoom();
		if (zoom < max - levelCount) {
			smallerCoef = 0;
		} else {
			smallerCoef = zoom - (max - levelCount);
		}

		switch (smallerCoef) {
			case 0:
				return 1;
			case 1:
				return 0.6;
			case 2:
				return 0.37;
			default:
				return 1;
		}
	}, [zoom, popupOpen]);

	const size = 50;

	return (
		<div
			style={{
				transform: `translateY(-50%) translateX(-50%)`,
				width: size,
				transition: "all 1s ease-in-out",
				filter: "drop-shadow(4px 4px 3px rgba(1,0,0,0.6))",
			}}
		>
			<div
				style={{
					transform: `scale(${scaleCoef * 100}%)`,
					transition: "all 0.2s ease-in-out",
				}}
			>
				<ZnaceniImage kod={sign.kodZnaceni} isOver={isOver} size={size} />
			</div>
			<HorizontalPopup sign={sign} open={popupOpen} marker={marker} />
		</div>
	);
}
