import React from "react";
import { getTrafficSignObjectDetail } from "../apis/TrafficSignObject";
import { TrafficSignObjectEditableDetail } from "../components/TrafficSignObject/TrafficSignObjectEditableDetail";
import { BreadcrumbSetter, Page, PageHeader, SkeletonLoader, useApiStateEffect } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface TrafficSignObjectScreenProps {
}

export const TrafficSignObjectScreen = ({}: TrafficSignObjectScreenProps) => {
	const { uzemiId, nosicSvisleId, objektSdzId } = useUrlParams();

	const [detailApiState] = useApiStateEffect(() => getTrafficSignObjectDetail(objektSdzId, nosicSvisleId, uzemiId));

	return (
		<Page>

			<SkeletonLoader
				data={[detailApiState]}
				render={([detail]) => (
					<>
						<BreadcrumbSetter
							to={getAbsoluteRoutePath("objektSvislehoZnaceni", { uzemiId, nosicSvisleId, objektSdzId })}
							label={`Objekt svislého značení ${detail.oznaceni}`}
						/>
						<PageHeader title={`Objekt svislého dopravního značení ${detail.oznaceni}`} />
						<TrafficSignObjectEditableDetail detail={detail} carrierId={nosicSvisleId}
														 districtId={uzemiId} />
					</>
				)}
			/>
		</Page>
	);


};