import { useNavigate } from "react-router-dom";
import { TrafficSignVerticalDto } from "../interfaces";
import React from "react";
import {
	createDiaryEntryForTrafficSignVertical,
	deleteDiaryEntryForTrafficSignVertical,
	deleteDiaryTrafficSignVerticalPhoto,
	getDiaryEntryDetailForTrafficSignVertical,
	getDiaryTrafficSignVerticalPhotoList,
	getRelatedDiaryEntriesForTrafficSignVertical,
	updateDiaryEntryForTrafficSignVertical,
	uploadDiaryTrafficSignVerticalPhoto,
} from "../apis/TrafficSignVertical";
import { DiaryParametricScreen, useAlert, useBreadcrumbs } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface TrafficSignVerticalDiaryScreenProps {
	signDetail: TrafficSignVerticalDto;
}

export const TrafficSignVerticalDiaryScreen = ({ signDetail }: TrafficSignVerticalDiaryScreenProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	const { uzemiId, nosicSvisleId, sdzHlaseniId } = useUrlParams();

	useBreadcrumbs({
		to: getAbsoluteRoutePath("svisleZnaceniHlaseni", { uzemiId, nosicSvisleId, sdzHlaseniId }),
		label: "Deníkové hlášení",
	});

	return <DiaryParametricScreen
		identifier={sdzHlaseniId}
		title={`Deníkové hlášení nosiče ${signDetail.oznaceni}`}
		getLink={(diaryEntry) => getAbsoluteRoutePath("svisleZnaceniHlaseni", {
			uzemiId,
			nosicSvisleId,
			sdzHlaseniId: diaryEntry.id,
		})}

		dispatchDetail={() => getDiaryEntryDetailForTrafficSignVertical(sdzHlaseniId, nosicSvisleId, uzemiId)}
		dispatchChildren={() => getRelatedDiaryEntriesForTrafficSignVertical(sdzHlaseniId, nosicSvisleId, uzemiId)}

		dispatchUpdate={(diaryEntry) => updateDiaryEntryForTrafficSignVertical(diaryEntry, nosicSvisleId, uzemiId)}
		onUpdateSuccess={() => {
			addSuccessAlert({ message: "Deníkové hlášení nosiče bylo upraveno." });
		}}

		dispatchDelete={() => deleteDiaryEntryForTrafficSignVertical(sdzHlaseniId, nosicSvisleId, uzemiId)}
		onDeleteSuccess={() => {
			addSuccessAlert({ message: "Deníkové hlášení nosiče bylo odstraněno." });
			navigate(
				getAbsoluteRoutePath("nosicSvislehoZnaceni", {
					uzemiId,
					nosicSvisleId,
				}),
			);
		}}

		dispatchCreate={(diaryEntry) => createDiaryEntryForTrafficSignVertical({
			...diaryEntry,
			parentId: sdzHlaseniId,
		}, nosicSvisleId)}
		onCreateSuccess={(diaryEntryId) => {
			addSuccessAlert({ message: "K závadě bylo přidáno hlášení." });
			navigate(
				getAbsoluteRoutePath("svisleZnaceniHlaseni", {
					uzemiId,
					nosicSvisleId,
					sdzHlaseniId: diaryEntryId,
				}),
			);
		}}

		dispatchPhotoList={() => getDiaryTrafficSignVerticalPhotoList(sdzHlaseniId, nosicSvisleId, uzemiId)}
		dispatchPhotoUpload={(file) => uploadDiaryTrafficSignVerticalPhoto(file, sdzHlaseniId)}
		dispatchPhotoDelete={(file) => deleteDiaryTrafficSignVerticalPhoto(file, sdzHlaseniId, nosicSvisleId, uzemiId)}
	/>;
};