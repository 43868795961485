import React from "react";
import { useCodeImage } from "../hooks/useCodeImage";

interface ZnaceniImageProps extends React.HTMLAttributes<HTMLDivElement> {
	kod: string;
	isOver?: boolean;
	size: number;
}

export default function ZnaceniImage(props: ZnaceniImageProps) {
	const { getSVGPath } = useCodeImage();

	const DEFAULT_IMAGE = "/default_sign_undefined.svg";
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column-reverse",
				justifyContent: "center",
				alignItems: "center",
				filter: `brightness(${props.isOver ? 80 : 100}%)`,
			}}
			{...props}
		>
			<div style={{ marginBottom: props.size * 0.0 }}>
				<img
					src={props.kod ? getSVGPath(props.kod) : DEFAULT_IMAGE}
					height={props.size}
					style={{ borderRadius: 5 }}
					onError={(e) => {
						// @ts-ignore
						const element: HTMLImageElement = e.target;
						element.src = DEFAULT_IMAGE;
					}}
				/>
			</div>
		</div>
	);
}
