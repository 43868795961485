import { useState } from "react";
import { TrafficSignObjectDto, TrafficSignObjectType } from "../../interfaces/TrafficSignObjectDto";
import { getFulltextSearch, SORT_DIRECTION, sortBySortMethod, sortStrings } from "@pasport/react-common";
import { SelectOption } from "@pasport/react-ui";

export enum TRAFFIC_SIGN_OBJECT_SORT_METHOD {
	OZNACENI = "OZNACENI",
	KOD = "KOD",
	STAV = "STAV",
}

export const TRAFFIC_SIGN_OBJECT_SORT_OPTIONS: SelectOption[] = [
	{ value: TRAFFIC_SIGN_OBJECT_SORT_METHOD.OZNACENI, label: "Označení" },
	{ value: TRAFFIC_SIGN_OBJECT_SORT_METHOD.KOD, label: "Kód značení" },
	{ value: TRAFFIC_SIGN_OBJECT_SORT_METHOD.STAV, label: "Stav objektu" },
];

export type TrafficSignObjectTypeValue = TrafficSignObjectType | "all"


export const TRAFFIC_SIGN_OBJECT_TYPE_SELECT_OPTIONS: SelectOption<TrafficSignObjectTypeValue, string>[] = [
	{
		value: "all",
		label: "Všechny objekty",
	},
	{
		value: TrafficSignObjectType.DOPRAVNI_ZNACKA,
		label: "Dopravní značka",
	},
	{
		value: TrafficSignObjectType.DOPRAVNI_ZRCADLO,
		label: "Dopravní zrcadlo",
	},
	{
		value: TrafficSignObjectType.DETEKTOR_RYCHLOSTI,
		label: "Detektor rychlosti",
	},
	{
		value: TrafficSignObjectType.SVETELNA_SIGNALIZACE,
		label: "Světelné signalizační zařízení",
	},
];


function getSortingFunc(key: TRAFFIC_SIGN_OBJECT_SORT_METHOD, sortDirection: SORT_DIRECTION): (a: TrafficSignObjectDto, b: TrafficSignObjectDto) => number {
	const increasingOrder = sortDirection === SORT_DIRECTION.UP;
	switch (key) {
		case TRAFFIC_SIGN_OBJECT_SORT_METHOD.KOD:
			return (a, b) => sortStrings(a.kodZnaceni, b.kodZnaceni, increasingOrder);
		case TRAFFIC_SIGN_OBJECT_SORT_METHOD.STAV:
			return (a, b) => sortStrings(a.stav, b.stav, increasingOrder);
		case TRAFFIC_SIGN_OBJECT_SORT_METHOD.OZNACENI:
		default:
			return (a, b) => sortStrings(a.oznaceni, b.oznaceni, increasingOrder);
	}
}


export const useTrafficSignObjectFilter = (objects: TrafficSignObjectDto[]) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [sortMethod, setSortMethod] = useState<TRAFFIC_SIGN_OBJECT_SORT_METHOD>(TRAFFIC_SIGN_OBJECT_SORT_METHOD.OZNACENI);
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);
	const [objectType, setObjectType] = useState<TrafficSignObjectTypeValue>("all");


	const filteredObject = objectType === "all" ? objects : objects.filter((item) => item.typ === objectType);

	const itemsFulltext = getFulltextSearch(searchQuery, filteredObject, item => [item.oznaceni, item.kodZnaceni]);
	const filteredItems = sortBySortMethod(itemsFulltext, getSortingFunc(sortMethod, sortDirection));
	return {
		setSearchQuery,
		setSortMethod,
		setSortDirection,
		setObjectType,
		filteredItems,
	};
};