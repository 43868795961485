import { TrafficSignHorizontalDto } from "../../interfaces";
import {
	apiToBoolean,
	apiToDate,
	apiToFloatNumber, apiToGeometry,
	apiToNumber,
	apiToString,
	booleanToApi, convertInnerLocationToGPS,
	dateToApi,
	LocationPoint,
	numberToApi,
	stringToApi,
} from "@pasport/react-common";
import {
	TrafficSignHorizontalApiDto,
	MapTrafficSignHorizontalDto,
	MapTrafficSignHorizontal,
} from "./TrafficSignHorizontalApiDto";


//TODO move to react-common package
const apiToGeometryWithCatch = (value: string | null): LocationPoint[][] | null => {
	try {
		if (value === "[[0, 0]]") return null;
		const location = apiToGeometry(value);
		if (location && location.length > 0 && location[0][0][0] !== 0) {
			return location;
		}
	} catch {
		return null;
	}
	return null;
};

export const mapApiToTrafficSignHorizontal = (response: TrafficSignHorizontalApiDto): TrafficSignHorizontalDto => {
	return {
		id: apiToNumber(response.vdz_id),
		datumPosledniUdrzby: apiToDate(response.datum_posl_servisu), //FIXME service vs udrzba
		datumPosledniZavady: apiToDate(response.datum_posl_zavady),
		stav: apiToString(response.stav),
		upresneniStavu: apiToString(response.stav_upresneni),

		komunikace: apiToString(response.komunikace),
		ulice: apiToString(response.ulice),
		rozmer: apiToFloatNumber(response.rozmer), //not represented in list
		cervenaBarva: apiToBoolean(response.cervena_barva),
		vodiciPruh: apiToBoolean(response.vodici_pruh),
		kodZnaceni: apiToString(response.kod_znaceni),
		kodSlovne: apiToString(response.znaceni_slovne),
		cisloPopisne: apiToString(response.cp_co),
		oznaceni: apiToString(response.oznaceni),
		poznamka: apiToString(response.poznamka),
		datumAktualizace: apiToDate(response.datum_aktualizace),
		datumInstalace: apiToDate(response.datum_instalace),
		datumPorizeni: apiToDate(response.datum_porizeni),

		tvar: apiToString(response.grafika),
		geometry: apiToGeometryWithCatch(response.geom),
		geometrie: apiToString(response.geom),

		datumPovoleni: apiToDate(response.datum_povoleni),
		povoleniPoznamka: apiToString(response.povoleni),
	};
};

export const mapTrafficSignHorizontalToApi = (request: TrafficSignHorizontalDto, isUpdate: boolean = false) => {
	return {
		oznaceni: stringToApi(request.oznaceni),
		ulice: stringToApi(request.ulice),
		cp_co: stringToApi(request.cisloPopisne),
		komunikace: stringToApi(request.komunikace),
		kod_znaceni: stringToApi(request.kodZnaceni),
		stav: stringToApi(request.stav),
		stav_upresneni: stringToApi(request.upresneniStavu),
		vodici_pruh: booleanToApi(request.vodiciPruh),
		cervena_barva: booleanToApi(request.cervenaBarva),
		rozmer: numberToApi(request.rozmer),
		poznamka: stringToApi(request.poznamka),

		DXdatum_instalace: dateToApi(request.datumInstalace),
		DXdatum_porizeni: dateToApi(request.datumPorizeni),
		DXdatum_aktualizace: dateToApi(request.datumAktualizace),

		DXdatum_povoleni: dateToApi(request.datumPovoleni),
		povoleni: stringToApi(request.povoleniPoznamka),
	};
};

export const mapApiToMapTrafficSignHorizontal = (data: MapTrafficSignHorizontalDto): MapTrafficSignHorizontal => {
	// Convert geometry to points
	const dtoGeom: string = "[" + data.geom + "]";
	const parsed: LocationPoint[][] = JSON.parse(dtoGeom.trim());
	const convertedLocation = parsed.map((group) => {
		return group.map((points) => {
			return convertInnerLocationToGPS(points);
		});
	});

	return {
		points: convertedLocation,
		id: data.vdz_id,
		uzemiId: data.uzemi_id,
		znaceniSlovne: data.znaceni_slovne,
		kodZnaceni: data.kod_znaceni,
		oznaceni: data.oznaceni,
		grafika: data.grafika == "polygon" ? "polygon" : "line",
	};
};
