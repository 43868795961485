import { useNavigate } from "react-router-dom";
import { TrafficSignHorizontalDto } from "../interfaces";
import React from "react";
import {
	createDiaryEntryForTrafficSignHorizontal,
	deleteDiaryEntryForTrafficSignHorizontal,
	deleteDiaryTrafficSignHorizontalPhoto,
	getDiaryEntryDetailForTrafficSignHorizontal,
	getDiaryTrafficSignHorizontalPhotoList,
	getRelatedDiaryEntriesForTrafficSignHorizontal,
	updateDiaryEntryForTrafficSignHorizontal,
	uploadDiaryTrafficSignHorizontalPhoto,
} from "../apis/TrafficSignHorizontal";
import { DiaryParametricScreen, useAlert, useBreadcrumbs } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";

interface TrafficSignHorizontalDiaryScreenProps {
	signDetail: TrafficSignHorizontalDto;
}

export const TrafficSignHorizontalDiaryScreen = ({ signDetail }: TrafficSignHorizontalDiaryScreenProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	const { uzemiId, objektVdzId, vdzHlaseniId } = useUrlParams();

	useBreadcrumbs({
		to: getAbsoluteRoutePath("vodorovneZnaceniHlaseni", { uzemiId, objektVdzId, vdzHlaseniId }),
		label: "Deníkové hlášení",
	});

	return <DiaryParametricScreen
		identifier={vdzHlaseniId}
		title={`Deníkové hlášení vodorovného objektu ${signDetail.oznaceni}`}
		getLink={(diaryEntry) => getAbsoluteRoutePath("vodorovneZnaceniHlaseni", {
			uzemiId,
			objektVdzId,
			vdzHlaseniId: diaryEntry.id,
		})}

		dispatchDetail={() => getDiaryEntryDetailForTrafficSignHorizontal(vdzHlaseniId, objektVdzId, uzemiId)}
		dispatchChildren={() => getRelatedDiaryEntriesForTrafficSignHorizontal(vdzHlaseniId, objektVdzId, uzemiId)}

		dispatchUpdate={(diaryEntry) => updateDiaryEntryForTrafficSignHorizontal(diaryEntry, objektVdzId, uzemiId)}
		onUpdateSuccess={() => {
			addSuccessAlert({ message: "Deníkové hlášení vodorovného objektu bylo upraveno." });
		}}

		dispatchDelete={() => deleteDiaryEntryForTrafficSignHorizontal(vdzHlaseniId, objektVdzId, uzemiId)}
		onDeleteSuccess={() => {
			addSuccessAlert({ message: "Deníkové hlášení vodorovného objektu bylo odstraněno." });
			navigate(
				getAbsoluteRoutePath("objektVodorovnehoZnaceni", {
					uzemiId,
					objektVdzId,
				}),
			);
		}}

		dispatchCreate={(diaryEntry) => createDiaryEntryForTrafficSignHorizontal({
			...diaryEntry,
			parentId: vdzHlaseniId,
		}, objektVdzId)}
		onCreateSuccess={(diaryEntryId) => {
			addSuccessAlert({ message: "K závadě bylo přidáno hlášení." });
			navigate(
				getAbsoluteRoutePath("vodorovneZnaceniHlaseni", {
					uzemiId,
					objektVdzId,
					vdzHlaseniId: diaryEntryId,
				}),
			);
		}}


		dispatchPhotoList={() => getDiaryTrafficSignHorizontalPhotoList(vdzHlaseniId, objektVdzId, uzemiId)}
		dispatchPhotoUpload={(file) => uploadDiaryTrafficSignHorizontalPhoto(file, vdzHlaseniId)}
		dispatchPhotoDelete={(file) => deleteDiaryTrafficSignHorizontalPhoto(file, vdzHlaseniId, objektVdzId, uzemiId)}
	/>;
};