import { convertGPSToInnerLocations, LocationPoint, stringToApi } from "@pasport/react-common";


const isLocationEmpty = (value: LocationPoint[][] | null): value is null => {
	return !value || value.length === 0 || value[0].length === 0;
};


export const geometryToApi = (geometry: LocationPoint[][] | null, type: "point" | "linie" | "polygon") => {
	if (!geometry) {
		return "";
	}

	const realGeometry = convertGPSToInnerLocations(geometry);

	if (isLocationEmpty(realGeometry))
		return stringToApi(null);

	if (type === "polygon") {
		return stringToApi(realGeometry.map(item => JSON.stringify([item])).join(","));
	} else {
		return stringToApi(realGeometry.map(item => JSON.stringify(item)).join(","));
	}

};