import { useParams } from "react-router-dom";
import { RouterParamName } from "./routerPaths";

type UseUrlParams = {

	uzemiId: number,
	objektVdzId: number,
	nosicSvisleId: number,
	objektSdzId: number,
	sdzHlaseniId: number,
	vdzHlaseniId: number
}

export const useUrlParams = (): UseUrlParams => {
	const params = useParams<RouterParamName>();
	return {
		uzemiId: Number(params.uzemiId),
		objektVdzId: Number(params.objektVdzId),
		nosicSvisleId: Number(params.nosicSvisleId),
		objektSdzId: Number(params.objektSdzId),
		sdzHlaseniId: Number(params.sdzHlaseniId),
		vdzHlaseniId: Number(params.vdzHlaseniId),
	};
};