import { TrafficSignCodeDto } from "../../interfaces";
import { TrafficSignCodeApiDto, TrafficSignObjectApiDto } from "./TrafficSignObjectApiDto";

import {
	TrafficSignObjectDto,
	TrafficSignObjectSize,
	TrafficSignObjectType,
} from "../../interfaces/TrafficSignObjectDto";
import {
	apiToBoolean,
	apiToDate,
	apiToNumber,
	apiToString,
	booleanToApi,
	dateToApi,
	stringToApi,
} from "@pasport/react-common";

export const mapApiToTrafficSignObject = (response: TrafficSignObjectApiDto): TrafficSignObjectDto => {
	const typ = apiToString(response.typ_sdz) as TrafficSignObjectType;
	return {
		id: apiToNumber(response.sdz_id),
		oznaceni: apiToString(response.oznaceni),
		stav: apiToString(response.stav),
		upresneniStavu: apiToString(response.stav_upresneni),
		datumAktualizace: apiToDate(response.datum_aktualizace),
		datumInstalace: apiToDate(response.datum_instalace),
		datumPorizeni: apiToDate(response.datum_porizeni),
		kodZnaceni: typ === TrafficSignObjectType.DOPRAVNI_ZNACKA ? apiToString(response.kod_znaceni) : "",
		kodSlovne: apiToString(response.znaceni_slovne),
		poznamka: apiToString(response.poznamka),
		typ,
		zluteOramovani: apiToBoolean(response.zlute_oramovani),
		reflexni: apiToBoolean(response.reflexni),
		velikostZnacky: apiToString(response.velikost) as TrafficSignObjectSize,
		oznaceniNosice: apiToString(response.oznaceni_nosice),
		datumPovoleni: apiToDate(response.datum_povoleni),
		povoleniPoznamka: apiToString(response.povoleni),
	};

};

export const mapApiToTrafficSignCode = (response: TrafficSignCodeApiDto): TrafficSignCodeDto => {
	return {
		kod: apiToString(response.kod_znaceni),
		nazev: apiToString(response.znaceni_slovne),
		tvar: apiToString(response.grafika || ""),
	};
};

export const mapTrafficSignObjectToApi = (request: TrafficSignObjectDto, isUpdate: boolean) => {


	const kodZnaceniRequest = request.typ === TrafficSignObjectType.DOPRAVNI_ZNACKA ? request.kodZnaceni : "";

	return {
		oznaceni: stringToApi(request.oznaceni),
		typ_sdz: stringToApi(request.typ),
		IXkod_znaceni: stringToApi(kodZnaceniRequest),
		stav: stringToApi(request.stav),
		stav_upresneni: stringToApi(request.upresneniStavu),
		velikost: stringToApi(request.velikostZnacky),
		reflexni: booleanToApi(request.reflexni),
		zlute_oramovani: booleanToApi(request.zluteOramovani),
		DXdatum_instalace: dateToApi(request.datumInstalace),
		DXdatum_porizeni: dateToApi(request.datumPorizeni),
		DXdatum_aktualizace: dateToApi(request.datumAktualizace),
		poznamka: stringToApi(request.poznamka),

		DXdatum_povoleni: dateToApi(request.datumPovoleni),
		povoleni: stringToApi(request.povoleniPoznamka),
	};

};