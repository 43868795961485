import { TrafficSignObjectItem } from "./TrafficSignObjectItem";
import { TrafficSignObjectDto } from "../../interfaces/TrafficSignObjectDto";
import { TrafficSignObjectListFilter } from "./TrafficSignObjectListFilter";
import { useTrafficSignObjectFilter } from "./useTrafficSignObjectFilter";

import { Stack } from "@pasport/react-ui";
import { ListContainer, Spacing } from "@pasport/react-common";

interface TrafficSignObjectListProps {
	items: TrafficSignObjectDto[],
	getLink: (sign: TrafficSignObjectDto) => string
}

export const TrafficSignObjectList = ({ items, getLink }: TrafficSignObjectListProps) => {
	const {
		setSortMethod,
		setSortDirection,
		setSearchQuery,
		setObjectType,
		filteredItems,
	} = useTrafficSignObjectFilter(items);
	return (
		<>
			<TrafficSignObjectListFilter
				setSearchQuery={setSearchQuery}
				setSortMethod={setSortMethod}
				setSortDirection={setSortDirection}
				setObjectType={setObjectType}
			/>
			<Spacing space={2} />
			<ListContainer
				nextPage={100}
				items={filteredItems}
				render={(renderItems) => (
					<Stack space={2}>
						{renderItems.map(item => <TrafficSignObjectItem key={item.id} value={item}
																		link={getLink(item)} />)}
					</Stack>
				)}
				emptyLabel={"Filtrům neodpovídá žádný objekt svislého dopravního značení."}
			/>


		</>
	);
};